import Toast from '@/libs/toast'
import loading from '@/libs/loading'

const toast = new Toast()

const axios = require('axios')

function validateStatus(status) {
  return status >= 200 && status < 300
}

const serverUrl = process.env.VUE_APP_SERVER_URL

async function service(defaultOptions, customOptions = {}, noToast) {
  const options = Object.assign(defaultOptions, customOptions)
  let timeId = setTimeout(() => {
    timeId = null
    loading.showLoading()
  }, 100)
  let result = null
  await axios(options)
    .then((res) => {
      if (timeId) {
        clearTimeout(timeId)
      } else {
        loading.closeLoading()
      }
      if (res.data && res.data.code === 0) {
        result = res.data.data
        return
      }
      if (res.data && res.data.code === undefined) {
        result = res.data
        return
      }
      if (res.data && res.data.code !== 0 && !noToast) {
        let { msg } = res.data
        if (res.data.code === 422) {
          msg = 'Account is not activated'
        }
        toast.show(msg)
      }
      result = Promise.reject(res.data)
    }, (error) => {
      loading.closeLoading()
      if (!noToast && /Network/.test(error)) {
        toast.show('Network Unavailable')
      }
      result = Promise.reject(error)
    })
  // console.log(result)
  return result
}

export default {
  get(baseUrl, query = {}, customOptions, noToast = false) {
    let url = /http/.test(baseUrl) ? `${baseUrl}?` : `${serverUrl}${baseUrl}?`
    Object.keys(query).forEach((key) => {
      // eslint-disable-next-line
      var val = query[key]
      if (val !== undefined) {
        url += `${key}=${encodeURIComponent(val)}&`
      }
    })
    // if (needUserId) {
    //   url += 'userid=sunjialu'
    // }
    console.log(url)
    const options = {
      method: 'get',
      url,
      withCredentials: true,
      validateStatus,
    }
    return service(options, customOptions, noToast)
  },
  post(baseUrl, data = {}, customOptions, noToast = false) {
    const params = data
    const url = /http/.test(baseUrl) ? baseUrl : `${serverUrl}${baseUrl}`
    console.log('url -->', url)
    const fd = new FormData()
    // if (needUserId) {
    //   params.userid = 'sunjialu'
    // }
    console.log('params --> ', params)
    Object.keys(params).forEach((key) => {
      console.log('this key -->', key)
      if (key === 'comcontext') {
        console.log('length --> ', params[key])
        for (let i = 0; i < params[key].length; i += 1) {
          console.log('key --> ', key);
          console.log('value -->', params[key]);
          fd.append(key, params[key][i])
        }
      } else if (params[key] !== undefined) {
        fd.append(key, params[key])
      }
    })
    // console.log('fd ---> ', fd.get('q'))
    const options = {
      method: 'post',
      url,
      mode: 'cors',
      withCredentials: true,
      validateStatus,
      data: fd,
    }
    return service(options, customOptions, noToast)
  },

}
