class Loading {
  constructor() {
    this.loadingNum = 0
    this.loadingDom = document.createElement('div')
    const svg = '<svg viewBox="0 0 1024 1024" data-icon="loading" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class="anticon-spin"><path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path></svg>'
    const runkeyframas = `
      @keyframes loading {
      from{
          transform: rotate(0deg);  
      } 
      to {
          transform: rotate(360deg);
      } 
    `
    this.loadingDom.innerHTML = svg
    this.loadingDom.style.position = 'fixed'
    this.loadingDom.style.fontSize = '24px'
    this.loadingDom.style.left = '50%'
    this.loadingDom.style.top = '50%'
    this.loadingDom.style.marginLeft = '-0.5em'
    this.loadingDom.style.marginTop = '-0.5em'
    this.loadingDom.style.animation = 'loading 1s infinite linear'
    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = runkeyframas;
    document.getElementsByTagName('head')[0].appendChild(style)
  }

  showLoading() {
    if (this.loadingNum === 0) {
      document.body.appendChild(this.loadingDom)
    }
    this.loadingNum += 1
  }

  closeLoading(delay = 300) {
    console.log('close')
    setTimeout(() => {
      this.loadingNum = Math.max(this.loadingNum - 1, 0)
      if (this.loadingNum === 0) {
        document.body.removeChild(this.loadingDom)
      }
    }, delay)
  }
}

export default new Loading()
