<!--
* 通用组件：feaute-list.
* 主要用途：对产品的一个特性进行图文描述。
* 目前支持两种形式： 左图右文和右图左文的简单切换，通过isLeft 的true/false 进行变换。
-->
<template lang="pug">
div(:class="[isLeft ? 'feature_box_left' : 'feature_box_right']")
  .feature_image(v-if="!isLeft")
    img.feature_img(:src="featureImage" alt="image about dictionary features")
  .feature_text
      .title_panel
        span.first_word {{featureTitle[0]}}
        span.last_word  {{featureTitle[1]}}
      .description
        span.main_content(v-html="$TT(mainText)")
  .feature_image(v-if="isLeft")
    img.feature_img(:src="featureImage" alt="image about dictionary features")
</template>
<script>
import { getLangClass } from '@/utils/index'

export default {
  name: 'featureList',
  data() {
    return {
      featureTitle: '',
      lang: '',
    };
  },
  props: {
    title: {
      type: Array,
      default: () => [],
    },
    mainText: {
      type: String,
      default: '',
    },
    featureImage: {
      type: String,
      default: '',
    },
    isLeft: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    '$store.state.selectLang': {
      handler(val) {
        const newStr = this.title[0].concat(' ').concat(this.title[1]);
        this.featureTitle = this.$TT(newStr).split('/');
        this.lang = getLangClass(val)
      },
      immediate: true,
    },
  },
}
</script>
<style lang="stylus" scoped>
.feature_box_left
  display flex
  justify-content center
  .feature_text
    width 450px
  .feature_image
    margin-left 100px
    margin-top 6px
.feature_box_right
  display flex
  justify-content center
  .feature_text
    width 450px
    margin-left 149px
.title_panel
  font-family Gilroy
  font-weight 900
  font-size 46px
  line-height 57px
  margin-top 108px
  .first_word
    color #FF592B
.description
  font-weight 400
  font-size 18px
  line-height 24px
  letter-spacing 0.1px
  color #1E1E20
.feature_img
  width 422px
  height 345px
.ar
  .description,.title_panel
    direction rtl !important
    text-align right !important
</style>
