<!--
通用组件：swiper-box 组件。
用途：与 video-swiper 搭配使用，用于对单个 box 进行判断，是图片，还是视频，视频是横版还是竖版的视频。
-->
<template lang="pug">
.anime_box
  //- 图片
  .image_box(v-if="swiperData.type === 'image'")
    media-box(
      :link="swiperData.poster"
      :type="swiperData.type"
      :author="swiperData.author"
      :author_icon="swiperData.icon"
      :viewers="swiperData.viewers"
      :describe="swiperData.describe"
    )
  //- 竖版视频
  .vertical_box(v-if="swiperData.type === 'portraitVideo'")
    media-box(
      :link="swiperData.poster"
      :playBtn="swiperData.playButtonSrc"
      :type="swiperData.type"
      :author="swiperData.author"
      :author_icon="swiperData.icon"
      :viewers="swiperData.viewers"
      :describe="swiperData.describe"
      @click.native="playVideo('main')"
    )
  //- 横版视频
  .horizontal_box(v-if="swiperData.type === 'horizontalVideo'")
    //- 视频1.
    .video_one
      media-box(
        :link="swiperData.poster"
        :playBtn="swiperData.playButtonSrc"
        :author="swiperData.mainAuthor"
        :type="swiperData.type"
        :author_icon="swiperData.mainIcon"
        :viewers="swiperData.mainViewers"
        :describe="swiperData.mainDescripe"
        @click.native="playVideo('main')"
      )
    //- 视频2
    .video_two
      media-box(
        :link="swiperData.extraPoster"
        :playBtn="swiperData.playButtonSrc"
        :author="swiperData.extraAuthor"
        :author_icon="swiperData.extraIcon"
        :type="swiperData.type"
        :viewers="swiperData.extraViewers"
        :describe="swiperData.extraDescripe"
        @click.native="playVideo('extra')"
      )
</template>
<script>
import mediaBox from './mediaBox.vue'

export default {
  name: 'swiperBox',
  components: {
    mediaBox,
  },
  data() {
    return {
      videoData: {},
    }
  },
  mounted() {
    this.videoData = this.swiperData
  },
  props: ['swiperData'],
  methods: {
    playVideo(type) {
      let data;
      if (type === 'main') {
        data = {
          src: this.videoData.mainSource,
          poster: this.videoData.poster,
          playBtn: this.videoData.playButtonSrc,
          visible: true,
        }
      } else {
        data = {
          src: this.videoData.extraSource,
          poster: this.videoData.extraPoster,
          playBtn: this.videoData.playButtonSrc,
          visible: true,
        }
      }
      /* 将所选中的视频资料上传到播放组件进行播放 */
      this.$store.dispatch('setVideoInfo', data);
    },
  },
  watch: {
    swiperData: (newVal) => {
      this.videoData = newVal;
    },
  },
}
</script>

<style lang="stylus" scoped>
.anime_box
  position absolute
  width 450px
  height 566p
  .horizontal_box
    .video_two
      margin-top 32px
</style>
