function resize() {
  const docEl = document.documentElement
  let clientWidth = docEl.clientWidth || document.body.clientWidth || window.innerWidth
  clientWidth = clientWidth > 375 ? 375 : clientWidth
  if (!clientWidth) return;
  const fz = (clientWidth / 375) * 100
  docEl.style.fontSize = `${fz}px`;
  // eslint-disable-next-line
  const realfz = ~~(+window.getComputedStyle(docEl).fontSize.replace('px', '') * 10000) / 10000
  if (fz !== realfz) {
    docEl.style.cssText = `font-size: ${fz * (fz / realfz)}px`;
  }
}
window.addEventListener('resize', resize)
resize()
