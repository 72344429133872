<!--
* 通用组件：footer, U-Dictionary 所有界面的通用页脚
* 用途: 主要用于 logo 展示以及版权信息等主要信息。
-->
<template lang="pug">
.dict_footer
  .footer_box
    .logo_show
      img.logo_image(v-if="show" src="@/assets/images/home/UD-logo.png")
      img.logo_image(v-if="!show" src="@/assets/images/home/UD-sp-logo.png")
      .logo_title(:key="selectLang")
        | {{$TT('U-Dictionary')}}
    .company_show
      span.copyright Copyright {{currYear}}, NetEase, Inc. All Rights Reserved.
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'dictFooter',
  data() {
    return {
      show: true,
      currYear: '',
    }
  },
  watch: {
    '$store.state.selectLang': {
      handler(val) {
        this.show = val !== 'Español';
      },
      immediate: true,
    },
  },
  mounted() {
    const date = new Date();
    this.currYear = date.getFullYear();
  },
  computed: {
    ...mapState(['selectLang']),
  },
}
</script>

<style lang="stylus" scoped>
.dict_footer
  position relative
  width 100%
  min-width 1300px
  height 64px
  background #F5F5F7
  .footer_box
    width 1300px
    margin 0 auto
    display flex
    .logo_show
      display flex
      margin-left 65px
      .logo_image
        margin-top 16px
        height 32px
      .logo_title
        font-family Gilroy
        font-style normal
        font-weight bold
        font-size 14px
        line-height 17px
        margin-left 14px
        margin-top 24px
        white-space nowrap
    .company_show
      margin-left 752px
      margin-top 24px
      font-family PingFang SC
      font-style normal
      font-weight normal
      font-size 12px
      line-height 17px
      letter-spacing 1px
      color: #B5B7B9;
</style>
