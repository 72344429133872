<!--
* Home Page 组件： pop up window
* 主要用途：用于视频弹窗，即点击视频后，出现弹窗并且在页面中央播放。
-->
<template lang="pug">
.pop_up(v-if="visible")
  .content_box
    transition(name="fade")
    .moda
    .video_box
      common-video.video(
          :videoSrc="this.$store.state.videoInfo.src"
          :posterSrc="this.$store.state.videoInfo.poster || ''"
          :videoStyle="{'border-radius': '20px 20px'}"
          :playBtnSrc= "playBtn"
      )
      .close
        img.btn(src="@/assets/images/swiper/close_btn.png" @click="close")

</template>

<script>
import { fixedBody, looseBody } from '@/utils/index'

export default {
  name: 'popUp',
  data() {
    return {
      visible: false,
      playBtn: require('@/assets/images/home/test.png'),
    }
  },
  methods: {
    close() {
      const data = {};
      this.$store.dispatch('setVideoInfo', data);
    },
  },
  watch: {
    /* eslint-disable */
    '$store.state.videoInfo': {
      handler (val) {
      this.visible = val.visible || false;
      console.log('info --> ', val);
      }
    },
    visible: {
      handler (newVal, oldVal) {
        /* 在弹窗播放视频时，动画和页面都处于锁定状态，关闭弹窗后恢复 */
        if(newVal === true){
          fixedBody();
          this.$store.state.animation.pause();
        } else {
          looseBody();
          this.$store.state.animation.play();
        }
      }
    }
  },
}
</script>

<style lang="stylus" scoped>
.pop_up
  width 100%
  min-width 960px
  .content_box
    width 1300px
    margin 0 auto
    .moda
      position fixed
      left 0
      right 0
      top 0
      bottom 0
      opacity 0.6
      background #000000
      z-index 1000
    .video_box
      position fixed
      z-index 1002
      top 50%
      left 50%
      height 500px
      width 800px
      transform: translate(-50%, -50%);
    .close
      position relative
      top: -99.5%
      left: 96%
      z-index 1002
      .btn
        width 22px
  .fade-enter
    opacity 0
  .fade-leave
    opacity 0.6
  .fade-enter-active, .fade-leave-active
    transition opacity 0.3s
  .fade-enter-to
    opacity 0.6
  .fade-leve-to
    opacity 0
  /deep/ .vjs-big-play-button
    left 360px
    top 230px
  /deep/ .vjs-time-tooltip
    top -4.8em
  /deep/ .vjs-play-progress:before
    top -.5em
  /deep/ .vjs-progress-control
   &:hover
    font-size .6em
</style>
