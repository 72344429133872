/*
* 在没有接口的时候，模仿接口提供数据
*/
/* eslint-disable */
export const data = {
  /* navlist: 用于实现navbar中的各个导航栏 */
  navList:[
    {
      name: 'Home',
      link: 'home',
    },
    {
      name: 'Translator',
      link: 'translate',
    },
    {
      name: 'Media Kit',
      link: 'Media',
    },
    {
      name: 'Articles',
      link: 'Article',
    },
    {
      name: 'FAQ',
      link: 'FAQ',
    },
    {
      name: 'Recruitment',
      link: 'Recruitment',
    },
  ],

  /*  页面语言的选择 */
  languageList: ['English', 'Español', 'Bahasa', 'العربية'],

  /*  Home 页面的图文介绍的内容 */
  features: [
    {
      title: ['Oxford', 'Dictionary'],
      mainText: 'Learn from a huge number of authoritative word definitions and sample sentences!',
      image: require('@/assets/images/home/feature_oxford.png'),
      isLeft: true,
    },
    {
      title: ['Magic', 'Translate'],
      mainText: 'Translate inside apps like <strong>WhatsApp, Youtube, Facebook</strong> and more. No need to copy the text or open the translator.',
      image: require('@/assets/images/home/feature_magic.png'),
      isLeft: false,
    },
    {
      title: ['Camera', 'Translate'],
      mainText: 'Snap a picture to instantly translate any text around you. <strong>Recognizes 100 languages.</strong>',
      image: require('@/assets/images/home/feature_camera.png'),
      isLeft: true,
    },
    {
      title: ['Voice', 'Translate'],
      mainText: 'Voice to voice translation between 49 accents, 35 languages.',
      image: require('@/assets/images/home/feature_voice.png'),
      isLeft: false,
    },
  ],

  /* Home 界面轮播图的内容
  * @params: type - 媒体类型
  * @params: poster - 视频加载封面
  * @params: mainSource - 主视频来源
  * 只有在横版视频由于没管需要同时放两个视频。
  * @params: extraPoster - 次视频封面
  * @params: extraSource - 此视频来源
  */
  
  swiperData:[
    {
      type: 'portraitVideo',
      poster: require('@/assets/images/swiper/Ale-Ivanova.png'),
      mainSource: 'https://ydlunacommon-cdn.nosdn.127.net/82289036d41fbf322263b961eada25a5.mp4',
    },
    {
      type: 'horizontalVideo',
      poster: require('@/assets/images/swiper/Juan-Guarnizo.png'),
      extraPoster: require('@/assets/images/swiper/Grace-en-Internet.png'),
      mainSource: 'https://ydlunacommon-cdn.nosdn.127.net/a3d02adcc66339dcb710e3f2fdb31ee6.mp4',
      extraSource: 'https://ydlunacommon-cdn.nosdn.127.net/eec910de2d93748e455688d3a9a2726a.mp4',
    },
    {
      type: 'horizontalVideo',
      poster: require('@/assets/images/swiper/Nessie-Judge.png'),
      extraPoster: require('@/assets/images/swiper/Alshad-Ahmad.png'),
      mainSource: 'https://ydlunacommon-cdn.nosdn.127.net/226d7f45f51e5d5376acecce64b9bce7.mp4',
      extraSource: 'https://ydlunacommon-cdn.nosdn.127.net/0450b1216b00c90b0a318767ccad4ff3.mp4',
    },
    {
      type: 'portraitVideo',
      poster: require('@/assets/images/swiper/US-tiktok.png'),
      mainSource: 'https://ydlunacommon-cdn.nosdn.127.net/5cbdf44b276f0414a7f26532c163df45.mp4',
    },
    {
      type: 'horizontalVideo',
      poster: require('@/assets/images/swiper/Aayu-Show.png'),
      extraPoster: require('@/assets/images/swiper/Chotu.png'),
      mainSource: 'https://ydlunacommon-cdn.nosdn.127.net/309a25d643549690b6ae0398bdc11367.mp4',
      extraSource: 'https://ydlunacommon-cdn.nosdn.127.net/c73662acf5fcf70c535648102ac33e34.mp4',
    },
    {
      type: 'portraitVideo',
      poster: require('@/assets/images/swiper/Junny.png'),
      mainSource: 'https://ydlunacommon-cdn.nosdn.127.net/ab45f11fae9708714038c5a27be9cf4a.mp4',
    },
    {
      type: 'horizontalVideo',
      poster: require('@/assets/images/swiper/Joe.png'),
      extraPoster: require('@/assets/images/swiper/Mai.png'),
      mainSource: 'https://ydlunacommon-cdn.nosdn.127.net/53c817ff2d376cd507e8c8e32c760523.mp4',
      extraSource: 'https://ydlunacommon-cdn.nosdn.127.net/b0f3ba5021b8b211ec6c8550661325cf.mp4',
    },
    {
      type: 'horizontalVideo',
      poster: require('@/assets/images/swiper/Omar.png'),
      extraPoster: require('@/assets/images/swiper/Tang.png'),
      mainSource: 'https://ydlunacommon-cdn.nosdn.127.net/cb2fa3ee646d85d8c9fbd29be8b168d5.mp4',
      extraSource: 'https://ydlunacommon-cdn.nosdn.127.net/6412ef7e207e908f1a1943bb1ea277c8.mp4',
    },
    {
      type: 'horizontalVideo',
      poster: require('@/assets/images/swiper/UDVN-1.png'),
      extraPoster: require('@/assets/images/swiper/UDVN-2.png'),
      mainSource: 'https://ydlunacommon-cdn.nosdn.127.net/304a985a6a2563148284c831b199f3b5.mp4',
      extraSource: 'https://ydlunacommon-cdn.nosdn.127.net/899f5edc422af3256a64ef4185d61823.mp4',
    },
    
  ],

  /* contact 列表 */
  contactIcons: [
    {
      icon: require('@/assets/images/home/contact_icon-email.png'),
      src: '#',
    },
    {
      icon: require('@/assets/images/home/contact_icon-twitter.png'),
      src: '#',
    },
    {
      icon: require('@/assets/images/home/contact_icon-facebook.png'),
      src: '#',
    },
    {
      icon: require('@/assets/images/home/contact_icon-ins.png'),
      src: '#',
    },
    {
      icon: require('@/assets/images/home/contact_icon-youtube.png'),
      src: '#',
    }
  ]
}