<!--
* Home page 组件: feature panel
* 用途：用于home page 中对词典特性的介绍
-->
<template lang="pug">
.feature_panel
  .content_box
    feature-list.feature_list(
      v-for="(feature, index) in featureData"
      :key="index"
      :title="feature.title"
      :mainText="feature.mainText"
      :featureImage="feature.image"
      :isLeft="feature.isLeft"
    )
</template>
<script>
import featureList from '@/components/pc/featureList.vue'
import { data } from '@/config/data'

export default {
  name: 'featurePanel',
  components: {
    featureList,
    lang: '',
  },
  data() {
    return {
      featureData: [],
    }
  },
  mounted() {
    this.featureData = data.features
  },
}
</script>

<style lang="stylus" scoped>
.feature_panel
  width 100%
  margin-top 108px
  min-width 1300px
  .content_box
    width 1300px
    margin 0 auto
    .feature_list
      margin-top 19px
      margin-bottom 75px
</style>
