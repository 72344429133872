<!--
* 通用组件：video-swiper 轮播组件，用于展示图片和视频。
* 用途：用于展示图片，视频（根据横版，竖版进行区分）
-->
<template lang="pug">
.video_swiper
  .boxes
    swiper-box(v-for="(swiper,index) in swipers" :swiperData="swiper" :key="index")
  input.progress(type="range" value="0" min="0" max="100", :class="{'safari': isSafari}")
</template>

<script>
import anime from 'animejs'
import { data } from '@/config/data'
import swiperBox from './swiperBox.vue'

export default {
  name: 'videoSwiper',
  components: {
    swiperBox,
  },
  data() {
    return {
      swipers: data.swiperData,
      animation: {},
      isSafari: window.navigator.userAgent.indexOf('Safari') !== -1 && window.navigator.userAgent.indexOf('Chrome') === -1,
    }
  },
  mounted() {
    const xTrans = [];
    let totalLen = 0;
    const progressEl = document.querySelector('.progress');
    anime.set('.anime_box', {
      /* 通过数量，宽度计算出每个元素的位置，以及总宽度。 */
      translateX: (el, i, len) => {
        xTrans[i] = { x: i * 485 };
        totalLen = 485 * len;
        return i * 485;
      },
    });
    const animation = anime({
      targets: xTrans, /* 所有元素的位置 */
      duration: 100000, /* 走一周持续时间 */
      easing: 'linear', /* 线性动画 */
      x: `+=${totalLen}`,
      direction: 'reverse', /* 方向 */
      autoplay: true,
      loop: true,
      update: () => {
        anime.set('.anime_box', {
          translateX: (el, i) => xTrans[i].x % totalLen, /* 每次的移动距离 */
        });
        progressEl.value = 100 - animation.progress; /* 进度条位置 */
      },
    })
    this.animation = animation
    /* eslint-disable */
    /* 监听事件，拖动进度条移动 */
    progressEl.addEventListener('input', function(){
      const ratio = animation.duration - (progressEl.value / 100) * animation.duration;
      animation.seek(ratio);
    });
    /* 聚焦状态，停止动画 */
    progressEl.addEventListener('focus', function(){
      animation.pause();
      console.log('down')
    })
    /* 未聚焦恢复动画 */
    progressEl.addEventListener('blur', function(){
      animation.play();
      console.log('up')
    })
    /* 记录动画 */
    this.$store.dispatch('setAnimation', animation);
  },
  beforeDestroy() {
    this.animation.pause()
  },
}
</script>

<style lang="stylus" scoped>
.video_swiper
  width 100%
  height 600px
  position relative
  margin 40px auto 0
  overflow hidden
  background #F5F5F7
  .boxes
    position relative
    left -450px
  .progress
    position absolute
    bottom 10px
    width 700px
    left 50%
    transform translateX(-350px)
    margin 0 auto
    &:hover
      cursor pointer
    &.safari
      -webkit-appearance none
      -moz-appearance none
      appearance none
      outline none
      background transparent
      height 8px
      border-radius 8px
      border 1px solid rgba(133, 133, 133, 0.5)
      box-sizing border-box
      &::-webkit-slider-thumb
        -webkit-appearance none
        appearance none
        background #007aff
        border 1px solid  #007aff
        width 16px
        height 16px
        border-radius 50%
        cursor pointer
</style>
