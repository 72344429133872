<template lang="pug">
#app
  router-view
</template>

<script>
export default {
  data() {
    return {
    }
  },
  created() {
    const ua = navigator.userAgent
    if (/iphone|android/i.test(ua)) {
      this.$router.replace({ name: 'mobile' })
    } else if (/#\/mobile/.test(window.location.href)) {
      this.$router.replace('/')
    }
  },
}
</script>

<style lang="stylus">
@import './styles/_reset.styl'
body
  font-family Girlory
</style>
