<!--
 * 通用组件：导航栏，用于页面之间的直接转化。
-->
<template lang="pug">
.navbar
  .nav_box
    .nav_icon
      .image(@click="toHome")
        img.logo(v-if="show" src="@/assets/images/home/UD-logo.png" alt="u-dictionary logo")
        img.logo(v-if="!show" src="@/assets/images/home/UD-sp-logo.png" alt="Traductor U logo")
      .logo_title
        | {{$TT('U-Dictionary')}}
    .nav_list
      router-link.nav_item(
        v-for="(item, index) in navList"
        :key="item.name"
        :to="{ name: `${item.link}`}"
      ) {{$TT(item.name)}}
    select.nav_dropdown(
      v-model="selectLang"
    )
      option.dropdown_content(v-for="(item, index) in langList" :key="index") {{item}}
</template>

<script>
import { getMobileLangClass } from '@/utils/index'
import { data } from '@/config/data'

export default {
  name: 'navBar',
  components: {
  },
  data() {
    return {
      show: true,
      langList: [],
      navList: [],
      selectLang: '',
      lang: '',
      href: '',
    }
  },
  watch: {
    selectLang: {
      handler(val) {
        if (!this.$route.params.lang || !this.$route.params.lang !== val) {
          this.$router.push(val);
        }
        this.$store.dispatch('setLanguage', val)
        this.show = val !== 'Español';
        this.$setLang(val);
        document.title = this.$TT('U-Dictionary:World\'s Best Translation APP')
      },

    },
  },
  mounted() {
    this.href = window.location.href;
    if (this.href.indexOf('traductoru.com') === -1) {
      this.lang = navigator.languages ? navigator.languages[0] : navigator.language;
    } else {
      this.lang = 'es';
    }
    this.lang = getMobileLangClass(this.lang);
    this.selectLang = this.$route.params.lang || this.lang
    this.langList = data.languageList;
    this.navList = data.navList;
  },
  methods: {
    toHome() {
      this.$router.push({
        name: 'home',
        path: `/${this.selectLang}`,
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
.navbar
  position fixed
  top 0
  width 100%
  min-width 1300px
  height 64px
  background #FFFFFF
  border 1px solid #B3B3B3
  z-index 999
  .nav_box
    width 1300px
    margin 0 auto
    display flex
    .nav_icon
      display flex
      .image
        margin-top 7px
        .logo
          width 45px
        &:hover
          cursor pointer
      .logo_title
        height 17px
        width 100px
        margin-left 14px
        margin-top 23px
        color #061C32
        font-size 14px
        line-height 17px
        font-weight bold
    .nav_list
      display flex
      margin-left 124px
      margin-top 25px
      .nav_item
        margin-right 30px
        height 17px
        font-size 17px
        line-height 17px
        color #1E1E20
        font-height 16px
        &:hover
          text-decoration-line underline
          text-decoration-color #FF4D17
          text-decoration-thickness 2px
          font-weight 600
    .nav_dropdown
      margin-left 150px
      margin-top 18px
      width 80px
      height 29px
      border 1px solid #E6E6E6
      border-radius 5px 100px 100px 5px
      .dropdown_content
        font-size 14px
        font-height 16px
        color #1E1E20
.ar
  .logo_title,.nav_item,.dropdown_content
    direction rtl !important
    text-align right !important
</style>
