<!--
* Home Page 组件: show panel
* 主要用途：根据页面设计主要用于展示底部的视频轮播及其周边样式。
-->
<template lang="pug">
.show-panel
  .show_header(:key="selectLang")
    img.header_num(src="@/assets/images/home/num_32.png")
    span.header_title {{$TT('Countries Covered')}}
  video-swiper
</template>

<script>
import videoSwiper from '@/components/pc/videoSwiper.vue'
import { mapState } from 'vuex';

export default {
  name: 'showPanel',
  components: {
    videoSwiper,
  },
  data() {
    return {
      coverHead: '',
    }
  },
  computed: {
    ...mapState(['selectLang']),
  },
}
</script>

<style lang="stylus" scoped>
.show-panel
  background #F5F5F7
  width 100%
  height 826px
  .show_header
    display flex
    justify-content center
    padding-top 62px
    height 39px
    .header_num
      margin-top 4px
    .header_title
      font-weight bold
      font-size 32px
      line-height 39px
      margin-left 18px
.ar
  .header_title
    direction rtl !important
    text-align right !important
</style>
