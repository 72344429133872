/* eslint-disable */
// import generateSign from '@/utils/sign'
import service from '../service'

async function getIp() {
  const baseUrl = 'https://interflow.youdao.com/api/location/local'
  return service.get(baseUrl, {}, {}, true)
}

export default {
  getIp,
}
