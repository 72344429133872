<!--
* home page 组件：固定头部下载栏，用于下载app
* 用途：当主页面原本的主下载页面消失后出现，固定页面头部，不会随着页面滚动而消失。
-->
<template lang="pug">
.download_panel(v-show="visible")
  .content_box
    .info
      span.info_title {{$TT("U-DICTIONARY")}}
      br
      span.info_text {{$TT("WORLD\'S BEST TRANSLATION APP")}}
    .download
      download-icon(size="small")
</template>

<script>
import downloadIcon from '@/components/pc/downloadIcon.vue'

export default {
  name: 'downloadPanel',
  components: {
    downloadIcon,
  },
  data() {
    return {
      visible: false,
    }
  },
  mounted() {
    window.addEventListener('scroll', () => {
      const ds = window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop
      /* 795时刚好下载页面滚出窗口，切换固定下载栏置于顶部。 */
      if (ds > 795) {
        this.visible = true;
      } else {
        this.visible = false;
      }
    })
  },
  method: {
  },
}

</script>

<style lang="stylus" scoped>
.download_panel
  position fixed
  width 100%
  min-width 1300px
  height 90px
  top 64px
  background radial-gradient(81.67% 81.67% at 88.52% 8.8%, #FAF4DD 0%, #CEEEEC 100%)
  border 0.5px solid #FFFFFF
  box-shadow 0px 2px 20px rgba(0, 0, 0, 0.143384)
  z-index 999
  .content_box
    display flex
    width 1300px
    margin 0 auto
    .info
      margin-top 24px
      margin-left 45px
      height 42px
      .info_title
        font-size 20px
        font-weight bold
        line-height 24px
        color #061C32
      .info_text
        font-size 14px
        line-height 16px
        letter-spacing 0.35px
        color #061c32
    .download
      margin-left 610px
      margin-top 25px
.ar
  .info
    direction rtl !important
    text-align right !important
</style>
