/*
* 用于存储首页的广告数据，针对不同语言具有不同数据。
* @params: url: 图片地址。
* @params: color：背景颜色
* @params: link: 跳转地址
*/
const banners = {
  // 现在默认为英语，如果以后有本地化需求，可以根据语言进行本地化。
  English: [
    {
      url: require('@/assets/images/home/banner-En.png'),
      color: 'radial-gradient(81.67% 81.67% at 88.52% 8.8%, #FFFCEF 0%, #E0F5F4 100%)',
      showDownload: true,
    },
    {
      url: require('@/assets/images/home/banner-poster-En.png'),
      color: 'radial-gradient(81.67% 81.67% at 88.52% 8.8%, #FFFCEF 0%, #E0F5F4 100%)',
      link: 'https://ydlunacommon-cdn.nosdn.127.net/78222d1e9098283d9b3e2de7d120ff30.mov',
      type: 'video',
    },
  ],
  Español: [
    {
      url: require('@/assets/images/home/banner-Sp.png'),
      color: 'radial-gradient(81.67% 81.67% at 88.52% 8.8%, #FFFCEF 0%, #E0F5F4 100%)',
      showDownload: true,
    },
    {
      url: require('@/assets/images/home/banner-poster-Sp.png'),
      color: 'radial-gradient(81.67% 81.67% at 88.52% 8.8%, #FFFCEF 0%, #E0F5F4 100%)',
      link: 'https://ydlunacommon-cdn.nosdn.127.net/52d7ad5e294e472643f9b78c3c4bc16f.mp4',
      type: 'video',
    },
  ],
  Bahasa: [
    {
      url: require('@/assets/images/home/banner-Ba.png'),
      color: 'radial-gradient(81.67% 81.67% at 88.52% 8.8%, #FFFCEF 0%, #E0F5F4 100%)',
      showDownload: true,
    },
    {
      url: require('@/assets/images/home/banner-poster-En.png'),
      color: 'radial-gradient(81.67% 81.67% at 88.52% 8.8%, #FFFCEF 0%, #E0F5F4 100%)',
      link: 'https://ydlunacommon-cdn.nosdn.127.net/4ad7384f1e96df42c6693b53939befc0.mov',
      type: 'video',
    },
  ],
  العربية: [
    {
      url: require('@/assets/images/home/banner-Ar.png'),
      color: 'radial-gradient(81.67% 81.67% at 88.52% 8.8%, #FFFCEF 0%, #E0F5F4 100%)',
      showDownload: true,
    },
    {
      url: require('@/assets/images/home/banner-poster-En.png'),
      color: 'radial-gradient(81.67% 81.67% at 88.52% 8.8%, #FFFCEF 0%, #E0F5F4 100%)',
      link: 'https://ydlunacommon-cdn.nosdn.127.net/cea0a99bce03cda6ee1a13a584732625.mov',
      type: 'video',
    },
  ],
}

export default {
  banners,
}
