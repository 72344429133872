import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home/Home.vue'

Vue.use(Router)

const router = new Router({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/mobile',
      name: 'mobile',
      component: () => import('./views/Mobile/Mobile.vue'),
    },
    {
      path: '/delete-account',
      name: 'DeleteAccount',
      component: () => import('./views/DeleteAccount/DeleteAccount.vue'),
    },
    {
      path: '/:lang',
      name: 'home',
      component: Home,
    },
    {
      path: '/Translator/:lang',
      name: 'translate',
      component: () => import('./views/Translate/Translate.vue'),
    },
    {
      path: '/articles/:lang',
      name: 'Article',
      component: () => import('./views/Articles/Articles.vue'),
    },
    {
      path: '/mediaKit/:lang',
      name: 'Media',
      component: () => import('./views/MediaKit/MediaKit.vue'),
    },
    {
      path: '/faq/:lang',
      name: 'FAQ',
      component: () => import('./views/FAQ/FAQ.vue'),
    },
    {
      path: '/recruitment/:lang',
      name: 'Recruitment',
      component: () => import('./views/Recruitment/Recruitment.vue'),
    },
    {
      path: '/',
      name: 'home',
      component: Home,
    },
  ],
})

// 为了消除bug：Avoided redundant navigation to current location:
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
export default router
