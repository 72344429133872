/*
* 根据语言不同提供对应语言的对应联系方式
*/
const contactInfo = {
  English: {
    youtube: 'https://www.youtube.com/channel/UCJPIDjpTCVoc94Ypy47NryA',
    facebook: 'https://www.facebook.com/UDictionary',
    instagram: 'https://www.instagram.com/udictionary_eng/',
    email: 'mailto:udictionary01@gmail.com',
    tiktok: 'https://vm.tiktok.com/ZSJQpYsqK/',
  },
  Español: {
    youtube: 'https://www.youtube.com/channel/UC_8lf_PSBsDGn-4nLlskX_A/videos',
    facebook: 'https://www.facebook.com/Traductor-U-343127586455068/',
    instagram: 'https://www.instagram.com/udictionary_es/',
    email: ' mailto:latam@u-dictinary.com',
    tiktok: 'https://www.tiktok.com/@traductor_u',
  },
  Bahasa: {
    youtube: 'https://www.youtube.com/channel/UCveneuwey7j_sm-ZKqf4vsQ',
    facebook: '',
    instagram: 'https://www.instagram.com/udictionary_indo/',
    email: 'mailto:Indonesia@u-dictionary.com',
    tiktok: 'https://vt.tiktok.com/ZSJC88tGn/',
  },
  العربية: {
    youtube: 'https://www.youtube.com/channel/UCmwzDKkQQDfPcpV15tAZsJw/featured',
    facebook: 'https://www.facebook.com/U-DictionaryMENA-102051658554266',
    instagram: 'https://www.instagram.com/udictionary_ar/',
    email: 'mailto:arabic@u-dictionary.com',
    tiktok: 'https://vm.tiktok.com/ZMd6xdEjw/',
  },
}

export default {
  contactInfo,
}
