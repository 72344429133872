import { render, staticRenderFns } from "./dictContact.vue?vue&type=template&id=4cc1a96c&scoped=true&lang=pug&"
import script from "./dictContact.vue?vue&type=script&lang=js&"
export * from "./dictContact.vue?vue&type=script&lang=js&"
import style0 from "./dictContact.vue?vue&type=style&index=0&id=4cc1a96c&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cc1a96c",
  null
  
)

export default component.exports