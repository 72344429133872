<!--
* Home Page 组件：users-info
* 用途：展示一些用户信息。
-->
<template lang="pug">
div(:class="platform")
  .word_part
    .users_icon
      img.people_image(
        v-if="platform !== 'mobile'"
        src="@/assets/images/home/people_icon.png"
      )
      span.text {{ this.head }}
    .users_num
      span.nums {{this.usersNumber}}
      img.arrow(
        v-if="platform !== 'mobile'"
        src="@/assets/images/home/usersinfo_triangle-arrow.png"
      )
      img.people_image(
        v-if="platform === 'mobile'"
        src="@/assets/images/home/people_icon.png"
      )
</template>
<script>
import anime from 'animejs'

export default {
  name: 'usersInfo',
  data() {
    return {
      usersNumber: '100,000,000',
      head: '',
    }
  },
  props: ['platform'],
  mounted() {
    // const el = document.querySelector('.nums');
    /* eslint-disable */
    const obj = {
      initNum: 10000000,
    }
    anime({
      targets: obj,
      initNum: 10030000 + anime.random(0, 14000),
      duration: 3000,
      round: 1,
      easing: 'linear',
      update: () => {
        this.usersNumber = obj.initNum.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      }
    });
    this.head = this.$TT('Trusted by');
  },
  watch: {
    // 主要是以为国际化组件无法及时更新语言，所以只能通过监听语言的变化来实现及时更新。
    '$store.state.selectLang': {
      handler() {
        this.head = this.$TT('Trusted by')
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.pc
  width 100%
  min-width 1300px
  margin-top 140px
  .word_part
    width 894px
    height 124px
    margin 0 auto
    border 0.5px solid rgba(240, 240, 240, 0.5)
    border-radius 20px
    background url('~@/assets/images/home/userinfo_background.png')
    display flex
    .users_icon
      margin-left 55px
      .people_image
        margin-top 54px
      .text
        color #FFFFFF
        margin-left 25px
        margin-top 48px
        font-size 24px
        line-height 29px
        letter-spacing 1px
    .users_num
      margin-left 241px
      margin-top 15px
      .nums
        width 303px
        height 60px
        font-size 50px
        line-height 60px
        letter-spacing 3px
        font-weight bold
        color #FFFFFF
      .arrow
        margin-left 11px
        margin-top 55px
.mobile
  width 100%
  .word_part
    position relative
    width 88%
    margin-left 0.4rem
    background linear-gradient(270deg, #FF9316 0.02%, #FF0068 100%)
    background-clip text
    -webkit-text-fill-color transparent
    .users_icon
      font-size 16px
      line-height 0.19rem
      letter-spacing 0.4px
      font-weight bold
    .users_num
      margin-left 2px
      .nums
        font-size 32px
      .people_image
        position absolute
        top 0.25rem
        left 1.6rem
        width 0.2rem
</style>
