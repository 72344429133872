<!--
* 主页面: Home 页面，承担默认页面的功能。
-->
<template lang="pug">
.home_page(:class="lang")
  nav-bar
  download-panel
  dict-header
  users-info(platform="pc")
  feature-panel
  show-panel
  pop-up
  dict-contact
  dict-footer
</template>

<script>
import { getLangClass } from '@/utils/index'
import dictFooter from '@/components/pc/dictFooter.vue'
import navBar from '@/components/pc/navBar.vue'
import mediaBox from '@/components/pc/mediaBox.vue'
import dictHeader from './components/dictHeader.vue'
import featurePanel from './components/featurePanel.vue'
import showPanel from './components/showPanel.vue'
import dictContact from './components/dictContact.vue'
import usersInfo from './components/usersInfo.vue'
import downloadPanel from './components/downloadPanel.vue'
import popUp from './components/popUp.vue'

export default {
  name: 'Home',
  components: {
    navBar,
    dictHeader,
    featurePanel,
    showPanel,
    dictContact,
    dictFooter,
    usersInfo,
    downloadPanel,
    popUp,
    mediaBox,
  },
  data() {
    return {
      lang: '',
    }
  },
  beforeRouteEnter(from, to, next) {
    if (/iphone|android/i.test(navigator.userAgent)) {
      next({ name: 'mobile' })
      return
    }
    next()
  },
  watch: {
    '$store.state.selectLang': {
      handler(val) {
        this.lang = getLangClass(val);
      },
      immediate: true,
    },
  },
  created() {
    this.log('ud_web_download_button_view')
  },
}
</script>

<style lang="stylus" scoped="">
.home_page
  position relative
</style>
