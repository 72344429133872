class Toast {
  constructor(duration = 5000) {
    this.duration = duration
    this.timeId = null
    this.toast = document.createElement('div')
    this.toast.setAttribute('style', 'position:fixed;left:50%;top:50%;transform:translate(-50%,-50%);background:#000;color:#fff;border-radius:10px;padding: 10px 20px;')
  }

  show(msg, duration) {
    this.toast.innerText = msg
    if (this.timeId) {
      clearTimeout(this.timeId)
      this.close()
      setTimeout(() => {
        document.body.appendChild(this.toast)
      }, 300)
    } else {
      document.body.appendChild(this.toast)
    }
    this.timeId = setTimeout(() => {
      this.close()
    }, duration || this.duration)
  }

  close() {
    this.timeId = null
    document.body.removeChild(this.toast)
  }
}

export default Toast
