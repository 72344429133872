/*
* 用于国际化的转换。
*/
import Vue from 'vue'
import Inter from '@webfront-components-vue/vue-internationalization'

Vue.use(Inter)

export default new Inter({
  lang: 'English',
  /* eslint-disable */
  langWords: {
    "Oxford Dictionary": {
      "English": "Oxford/Dictionary",
      "Español": "Diccionarios/Oxford",
      "Bahasa": "Kamus/Oxford",
      "العربية": "قاموس/أكسفورد",
    },
    "Learn from a huge number of authoritative word definitions and sample sentences!": {
      "English": "Learn from a huge number of authoritative word definitions and sample sentences!",
      "Español": "¡Aprende de un gran número de definiciones y oraciones de ejemplo!",
      "Bahasa": "Belajar sejumlah besar definisi kata terpercaya dan contoh kalimat!",
      "العربية": "تعلم من عدد كبير من التعاريف الموثوقة للكلمات وامثلة الجمل!",
    },
    "Magic Translate": {
      "English": "Magic/Translate",
      "Español": "Traductor/mágico",
      "Bahasa": "Penerjemah/Ajaib",
      "العربية": "الترجمة/السحرية ",
    },
    "Translate inside apps like <strong>WhatsApp, Youtube, Facebook</strong> and more. No need to copy the text or open the translator.": {
      "English": "Translate inside apps like  <strong>WhatsApp, Youtube, Facebook</strong> and more. No need to copy the text or open the translator.",
      "Español": "Traduce dentro de aplicaciones como <strong>WhatsApp, Youtube, Facebook</strong> y más. Sin tener que copiar el texto o abrir el traductor.",
      "Bahasa": "Terjemahkan di dalam aplikasi seperti <strong>WhatsApp, Youtube, Facebook</strong>, dan lainnya. Tidak perlu menyalin teks atau membuka penerjemah.",
      "العربية": "ترجم داخل تطبيقات مثل <strong>WhatsApp و Youtube و Facebook</strong> والمزيد.لا حاجة لنسخ النص أو فتح المترجم.",
    },
    "Camera Translate": {
      "English": "Camera/Translation",
      "Español": "Traductor/de cámara",
      "Bahasa": "Penerjemah/Kamera",
      "العربية": "ترجمة/الكاميرا ",
    },
    "Snap a picture to instantly translate any text around you. <strong>Recognizes 100 languages.</strong>": {
      "English": "Snap a picture to instantly translate any text around you. <strong>Recognizes 100 languages.</strong>",
      "Español": "Traduce al instante cualquier texto. <strong>Reconoce 100 idiomas.</strong>",
      "Bahasa": "Potret untuk langsung menerjemahkan teks apa pun di sekitar Anda. <strong>Mengenali 100 bahasa.</strong>",
      "العربية": "التقط  صورة لترجمة أي نص من حولك على الفور.يو ديكشناري يتعرف على اكثر من 100 لغة.",
    },
    "Voice Translate": {
      "English": "Voice/Translation",
      "Español": "Traductor/de voz",
      "Bahasa": "Penerjemah/Suara",
      "العربية": "ترجمة/صوتية",
    },
    "Voice to voice translation between 49 accents, 35 languages.": {
      "English": "Voice to voice translation between 49 accents, 35 languages.",
      "Español": "Traduce entre 35 idiomas, detecta 49 acentos.",
      "Bahasa": "Terjemahan suara ke suara antara 49 aksen, 35 bahasa.",
      "العربية": "الترجمة الصوتية بين 49 لهجة و 35 لغة.",
    },
    "Countries Covered": {
      "English": "Countries Covered",
      "Español": "Países incluídos",
      "Bahasa": "Negara yang dicakup",
      "العربية": "البلدان التي يُغطيها",
    },
    "Follow and contact us": {
      "English": "Follow and contact us",
      "Español": "Síguenos y ponte en contacto",
      "Bahasa": "Ikuti dan hubungi kami",
      "العربية": "تابعنا وتواصل معنا ",
    },
    "If you are a content creator and would like to promote our app, contact us here or send an email to udictionary01@gmail.com": {
      "English": "If you are a content creator and would like to promote our app, contact us here or send an email to udictionary01@gmail.com",
      "Español": "Si usted es un creador de contenido y le gustaría promover nuestra aplicación, póngase en contacto con nosotros aquí o envíe un correo a udictionary01@gmail.com",
      "Bahasa": "Jika Anda seorang pembuat konten dan ingin mempromosikan aplikasi kami, hubungi kami di sini atau kirim email ke udictionary01@gmail.com",
      "العربية": "إذا كنت منشئ محتوى وترغب في الترويج لتطبيقنا ، فاتصل بنا هنا أو أرسل بريدًا إلى udictionary01@gmail.com",
    },
    "Home": {
      "English": "Home",
      "Español": "Inicio",
      "Bahasa": "Beranda",
      "العربية": "الرئيسية",
    },
    "Translate": {
      "English": "Translate",
      "Español": "Traducir",
      "Bahasa": "Terjemahan",
      "العربية": "ترجمة",
    },
    "Translator": {
      "English": "Translator",
      "Español": "Traducir",
      "Bahasa": "Terjemahan",
      "العربية": "ترجمة",
    },
    "Media Kit": {
      "English": "Media kit",
      "Español": "Media kit",
      "Bahasa": "Media Kit",
      "العربية": "مجموعة وسائل الإعلام",
    },
    "Articles": {
      "English": "Articles",
      "Español": "Artículos",
      "Bahasa": "Artikel",
      "العربية": "مقالات",
    },
    "FAQ": {
      "English": "FAQ",
      "Español": "Preguntas más frecuentes",
      "Bahasa": "Tanya Jawab",
      "العربية": "اسئلة شائعة ",
    },
    "Recruitment": {
      "English": "Recruitment",
      "Español": "Reclutamiento",
      "Bahasa": "Rekrutmen",
      "العربية": "توظيف",
    },
    "choose a language": {
      "English": "choose a language",
      "Español": "Cambiar idioma",
      "Bahasa": "Pilih sebuah bahasa",
      "العربية": "اختر لغة",
    },
    "U-DICTIONARY": {
      "English": "U-DICTIONARY",
      "Español": "Traductor U",
      "Bahasa": "U-DICTIONARY",
      "العربية": "يو ديكشناري",
    },
    "U-Dictionary": {
      "English": "U-Dictionary",
      "Español": "Traductor U",
      "Bahasa": "U-Dictionary",
      "العربية": "يو ديكشناري",
    },
    "APK DOWNLOAD": {
      "English": "APK DOWNLOAD",
      "Español": "DESCARGAR APK",
      "Bahasa": "Unduh APK",
      "العربية": "تنزيل APK",
    },
    "WORLD\'S BEST TRANSLATION APP": {
      "English": "WORLD\'S BEST TRANSLATION APP",
      "Español": "La mejor app para traducir",
      "Bahasa": "APLIKASI PENERJEMAH TERBAIK DI DUNIA",
      "العربية": "أفضل تطبيق للترجمة في العالم"
    },
    "U-Dictionary:World's Best Translation APP": {
      "English": "U-Dictionary:World's Best Translation APP",
      "Español": "Traductor U:La mejor app para traducir",
      "Bahasa": "U-DICTIONARY:APLIKASI PENERJEMAH TERBAIK DI DUNIA",
      "العربية": "أفضل تطبيق للترجمة في العالم:يو ديكشناري",
    },
    "Download": {
      "English": "Download",
      "Español": "Descargar",
      "Bahasa": "Unduh",
      "العربية": "تحميل",
    },
    "site language": {
      "English": "Site Language",
      "Español": "Idioma",
      "Bahasa": "Bahasa situs",
      "العربية": "لغات",
    },
    "Follow and contact us": {
      "English": "Follow and contact us",
      "Español": "Síguenos y ponte en contacto",
      "Bahasa": "Ikuti dan hubungi kami",
      "العربية": "تابعنا وتواصل معنا"
    },
    "If you are a content creator and would like to promote our app, contact us here or send mail to udictionary01@gmail.com": {
      "English": "If you are a content creator and would like to promote our app, contact us here or send mail to udictionary01@gmail.com",
      "Español": "Si usted es un creador de contenido y le gustaría promover nuestra aplicación, póngase en contacto con nosotros aquí o envíe un correo a udictionary01@gmail.com",
      "Bahasa": "Jika Anda seorang pembuat konten dan ingin mempromosikan aplikasi kami, hubungi kami di sini atau kirim email ke udictionary01@gmail.com",
      "العربية": "إذا كنت منشئ محتوى وترغب في الترويج لتطبيقنا ، فاتصل بنا هنا أو أرسل بريدًا إلى udictionary01@gmail.com"
    },
    "Countries Covered": {
      "English": "Countries Covered",
      "Español": "Países incluídos",
      "Bahasa": "Negara yang dicakup",
      "العربية":"البلدان التي يُغطيها",
    },
    "Trusted by": {
      "English": "Trusted by",
      "Español": "Confiado por",
      "Bahasa": "Dipercaya oleh",
      "العربية":"يثق به",
    },
    "ictionary": {
      "English": "ictionary",
      "Español": "Traductor U",
      "Bahasa": "U-DICTIONARY",
      "العربية":"تطبيق يو ديكشناري",
    },
    "Please enter text": {
      "English": "Please enter text",
      "Español": "Por favor introduzca el texto",
      "Bahasa": "Silakan masukkan teks",
      "العربية":"الرجاء إدخال النص",
    },
    "<span class='text'>U-Dictionary</span><span class='bold'>Media Kit</span>": {
      "English": "U-Dictionary Media Kit",
      "Español": "Media Kit de Traductor U",
      "Bahasa": "Kit Media U-Dictionary",
      "العربية":"مجموعة وسائل الإعلام لـ يو ديكشناري",
    },
    "Product Description": {
      "English": "Product Description",
      "Español": "Descripción del producto",
      "Bahasa": "Deskripsi Produk",
      "العربية":"وصف المنتج",
    },
    "PDF Download": {
      "English": "PDF Download",
      "Español": "Descargar PDF",
      "Bahasa": "Unduh PDF",
      "العربية":" PDF تحميل",
    },
    "Question Category": {
      "English": "Question Category",
      "Español": "Categoría de pregunta",
      "Bahasa": "Kategori Pertanyaan",
      "العربية":" فئة السؤال",
    },
    "Did not find the answer? Email us": {
      "English": "Did not find the answer? Email us",
      "Español": "¿No encontraste la respuesta a tu pregunta? Envíanos un email.",
      "Bahasa": "Tidak menemukan jawabannya? Email kami",
      "العربية":"لم تجد الجواب؟ ارسل لنا عبر الايميل",
    },
    // Recruitment page, 目前只有西班牙版本，其他跟随英文版本
    "Start a new job": {
      "English": "Start a new job",
      "Español": "Inicia un nuevo trabajo con nosotros.",
      "Bahasa": "Start a new job",
      "العربية":"Start a new job",
    },
    "Marketing Specialist": {
      "English": "Marketing Specialist",
      "Español": "Especialista en marketing",
      "Bahasa": "Marketing Specialist",
      "العربية":"Marketing Specialist",
    },
    "<span class='title'>Marketing Specialist</span>": {
      "English": "<span class='title'>Marketing Specialist</span>",
      "Español": "<span class='title'>Especialista en marketing</span>",
      "Bahasa": "<span class='title'>Marketing Specialist</span>",
      "العربية":"<span class='title'>Marketing Specialist</span>",
    },
    "Responsibilities": {
      "English": "Responsibilities",
      "Español": "Responsabilidades",
      "Bahasa": "Responsibilities",
      "العربية":"Responsibilities",
    },
    "Research social media influencers and build strong relationships with them through negotiation and execution of digital campaigns and regular communication.": {
      "English": "Research social media influencers and build strong relationships with them through negotiation and execution of digital campaigns and regular communication.",
      "Español": "Encuentre a las personas más influyentes de redes sociales y establezca relaciones sólidas con ellos a través de la negociación y ejecución de campañas digitales y comunicación regular.",
      "Bahasa": "Research social media influencers and build strong relationships with them through negotiation and execution of digital campaigns and regular communication.",
      "العربية":"Research social media influencers and build strong relationships with them through negotiation and execution of digital campaigns and regular communication."
    },
    "Review data on the performance of influencers campaigns and adjusts plans or strategies to optimize them.": {
      "English": "Review data on the performance of influencers campaigns and adjusts plans or strategies to optimize them.",
      "Español": "Revisar datos sobre el desempeño de las campañas de influencers y ajustar planes o estrategias para optimizarlas.",
      "Bahasa": "Review data on the performance of influencers campaigns and adjusts plans or strategies to optimize them.",
      "العربية":"Review data on the performance of influencers campaigns and adjusts plans or strategies to optimize them.",
    },
    "Support the marketing team in other social media, marketing & sales tasks.": {
      "English": "Support the marketing team in other social media, marketing & sales tasks.",
      "Español": "Apoyar al equipo de marketing en otras redes sociales, tareas de marketing y ventas.",
      "Bahasa": "Support the marketing team in other social media, marketing & sales tasks.",
      "العربية":"Support the marketing team in other social media, marketing & sales tasks.",
    },
    "Requirements": {
      "English": "Requirements",
      "Español": "Requisitos",
      "Bahasa": "Requirements",
      "العربية":"Requirements",
    },
    "Have passion on social media, experience working with Internet company will be preferred.": {
      "English": "Have passion on social media, experience working with Internet company will be preferred.",
      "Español": "Tener pasión por las redes sociales, se preferirá la experiencia de trabajar con una empresa de Internet.",
      "Bahasa": "Have passion on social media, experience working with Internet company will be preferred.",
      "العربية":"Have passion on social media, experience working with Internet company will be preferred.",
    },
    "English can be working language": {
      "English": "English can be working language.",
      "Español": "Inglés avanzado.",
      "Bahasa": "English can be working language.",
      "العربية":"English can be working language.",
    },
    "Familiarity with mainstream social media platforms, including but not limited to Facebook, YouTube, Instagram, Snapchat, Twitter and Google+.": {
      "English": "Familiarity with mainstream social media platforms, including but not limited to Facebook, YouTube, Instagram, Snapchat, Twitter and Google+.",
      "Español": "Familiaridad con las principales plataformas de redes sociales, incluidas, entre otras, Facebook, YouTube, Instagram, Snapchat, Twitter y Google+.",
      "Bahasa": "Familiarity with mainstream social media platforms, including but not limited to Facebook, YouTube, Instagram, Snapchat, Twitter and Google+.",
      "العربية":"Familiarity with mainstream social media platforms, including but not limited to Facebook, YouTube, Instagram, Snapchat, Twitter and Google+.",
    },
    "Detail oriented with strong written and verbal communication skills.": {
      "English": "Detail oriented with strong written and verbal communication skills.",
      "Español": "Orientado a los detalles con fuertes habilidades de comunicación verbal y escrita.",
      "Bahasa": "Detail oriented with strong written and verbal communication skills.",
      "العربية":"Detail oriented with strong written and verbal communication skills.",
    },
    "Must be willing to work in a fast-paced start-up environment and efficient.": {
      "English": "Must be willing to work in a fast-paced start-up environment and efficient.",
      "Español": "Deberá ser eficiente y capaz de trabajar en un ambiente de ritmo rápido.",
      "Bahasa": "Must be willing to work in a fast-paced start-up environment and efficient.",
      "العربية":"Must be willing to work in a fast-paced start-up environment and efficient.",
    },
    "Relevant experience of 1-3 years is an advantage.": {
      "English": "Relevant experience of 1-3 years is an advantage.",
      "Español": "La experiencia relevante de 1-3 años es una ventaja.",
      "Bahasa": "Relevant experience of 1-3 years is an advantage.",
      "العربية":"Relevant experience of 1-3 years is an advantage.",
    },
    "Click to post": {
      "English": "Click to post",
      "Español": "Haga clic para publicar",
      "Bahasa": "Click to post",
      "العربية":"Click to post",
    },
    "Operation Specialist": {
      "English": "Operation Specialist",
      "Español": "Especialista en operaciones",
      "Bahasa": "Operation Specialist",
      "العربية":"Operation Specialist",
    },
    "Responsibilities": {
      "English": "Responsibilities",
      "Español": "Responsabilidades",
      "Bahasa": "Responsibilities",
      "العربية":"Responsibilities",
    },
    "Find and communicate with the oursourcing companies.": {
      "English": "Find and communicate with the oursourcing companies.",
      "Español": "Responsabilidades",
      "Bahasa": "Find and communicate with the oursourcing companies.",
      "العربية": "Find and communicate with the oursourcing companies",
    },
    "Recruit part-time online editors and teach them to use our editing system.": {
      "English": "Recruit part-time online editors and teach them to use our editing system.",
      "Español": "Reclute editores en línea de medio tiempo y enséñeles a usar nuestro sistema de edición.",
      "Bahasa": "Recruit part-time online editors and teach them to use our editing system.",
      "العربية":"Recruit part-time online editors and teach them to use our editing system.",
    },
    "Assist Chinese colleagues in completing tasks related to the project.": {
      "English": "Assist Chinese colleagues in completing tasks related to the project.",
      "Español": "Ayudar a los colegas chinos a completar las tareas relacionadas con el proyecto.",
      "Bahasa": "Assist Chinese colleagues in completing tasks related to the project.",
      "العربية":"Assist Chinese colleagues in completing tasks related to the project.",
    },
    "Requirements": {
      "English": "Requirements",
      "Español": "Requisitos",
      "Bahasa": "Requirements",
      "العربية":"Requirements",
    },
    "Can use Chinese or English as the working language.": {
      "English": "Can use Chinese or English as the working language.",
      "Español": "Puede utilizar chino o inglés como idioma de trabajo.",
      "Bahasa": "Can use Chinese or English as the working language.",
      "العربية":"Can use Chinese or English as the working language.",
    },
    "A bachelor's degree": {
      "English": "A bachelor's degree",
      "Español": "Contar con licenciatura",
      "Bahasa": "A bachelor's degree",
      "العربية":"A bachelor's degree",
    },
    " Have a good command in computer operation, email correspondence, and use of Miscrosoft Office applications":  {
      "English": "Have a good command in computer operation, email correspondence, and use of Miscrosoft Office applications",
      "Español": "Tener un buen dominio del funcionamiento de la computadora, la correspondencia por correo electrónico y el uso de las aplicaciones de Miscrosoft Office.",
      "Bahasa": "Have a good command in computer operation, email correspondence, and use of Miscrosoft Office applications",
      "العربية":"Have a good command in computer operation, email correspondence, and use of Miscrosoft Office applications",
    },
    "Sense of responsibilty and good communication skills": {
      "English": "Sense of responsibilty and good communication skills",
      "Español": "Sentido de responsabilidad y buenas habilidades de comunicación.",
      "Bahasa": "Sense of responsibilty and good communication skills",
      "العربية":"Sense of responsibilty and good communication skills",
    },
    "Ability to work independently": {
      "English": "Ability to work independently",
      "Español": "Capacidad para trabajar de forma independiente",
      "Bahasa": "Ability to work independently",
      "العربية":"Ability to work independently",
    },
    "Marketing Intern(Graduate on 2022)": {
      "English": " Marketing Intern(Graduate on 2022)",
      "Español": "Practicante de marketing (Graduado en 2022)",
      "Bahasa": " Marketing Intern(Graduate on 2022)",
      "العربية":" Marketing Intern(Graduate on 2022)",
    },
    "Research social media influencers and build strong relationships with them through negotiation and execution of digital campaigns and regular communication.": {
      "English": "Research social media influencers and build strong relationships with them through negotiation and execution of digital campaigns and regular communication.",
      "Español": "Encuentre a las personas más influyentes de redes sociales y construya relaciones sólidas con ellos a través de la negociación y ejecución de campañas digitales y la comunicación regular.",
      "Bahasa": "Research social media influencers and build strong relationships with them through negotiation and execution of digital campaigns and regular communication.",
      "العربية":"Research social media influencers and build strong relationships with them through negotiation and execution of digital campaigns and regular communication.",
    },
    "Support the marketing team in other social media, marketing & sales tasks.": {
      "English": "Support the marketing team in other social media, marketing & sales tasks.",
      "Español": "Apoyar al equipo de marketing en otras redes sociales, tareas de marketing y ventas.",
      "Bahasa": "Support the marketing team in other social media, marketing & sales tasks.",
      "العربية":"Support the marketing team in other social media, marketing & sales tasks.",
    },
    "Have passion on social media, experience working with Internet company will be preferred.": {
      "English": "Have passion on social media, experience working with Internet company will be preferred.",
      "Español": "Tener pasión por las redes sociales, se preferirá la experiencia de trabajar con una empresa de Internet.",
      "Bahasa": "Have passion on social media, experience working with Internet company will be preferred.",
      "العربية":"Have passion on social media, experience working with Internet company will be preferred.",
    },
    "English can be working language.": {
      "English": "English can be working language.",
      "Español": "Inglés avanzado.",
      "Bahasa": "English can be working language.",
      "العربية":"English can be working language.",
    },
    "Familiarity with mainstream social media platforms, including but not limited to Facebook, YouTube, Instagram, Snapchat, Twitter and Google+.": {
      "English": "Familiarity with mainstream social media platforms, including but not limited to Facebook, YouTube, Instagram, Snapchat, Twitter and Google+.",
      "Español": "Familiaridad con las principales plataformas de redes sociales, incluidas, entre otras, Facebook, YouTube, Instagram, Snapchat, Twitter y Google+.",
      "Bahasa": "Familiarity with mainstream social media platforms, including but not limited to Facebook, YouTube, Instagram, Snapchat, Twitter and Google+.",
      "العربية":"Familiarity with mainstream social media platforms, including but not limited to Facebook, YouTube, Instagram, Snapchat, Twitter and Google+.",
    },
    "Can work at least 3 days every week.": {
      "English": "Can work at least 3 days every week.",
      "Español": "Puede trabajar al menos 3 días a la semana.",
      "Bahasa": "Can work at least 3 days every week.",
      "العربية":"Can work at least 3 days every week.",
    },
    "Marketing Specialist": {
      "English": "Marketing Specialist",
      "Español": "Especialista en marketing",
      "Bahasa": "Marketing Specialist",
      "العربية":"Marketing Specialist",
    },
    "Marketing Intern": {
      "English": "Marketing Intern",
      "Español": "Practicante de marketing (Graduado en 2022)",
      "Bahasa": "Marketing Intern",
      "العربية":"Marketing Intern",
    },
    "Operation Specialistt": {
      "English": "Operation Specialist",
      "Español": "Especialista en operaciones",
      "Bahasa": "Operation Specialist",
      "العربية":"Operation Specialist",
    },
    "Global Recruitment": {
      "English": "Global Recruitment",
      "Español": "Reclutamiento Global",
      "Bahasa": "Global Recruitment",
      "العربية":"Global Recruitment",
    },
    "Expand details": {
      "English": "Expand details",
      "Español": "Expandir detalles",
      "Bahasa": "Expand details",
      "العربية":"Expand details",
    },
    "Egypt": {
      "English": "Egypt",
      "Español": "Egipto",
      "Bahasa": "Egypt",
      "العربية":"Egypt",
    },
    "Vietnam": {
      "English": "Vietnam",
      "Español": "Vietnam",
      "Bahasa": "Vietnam",
      "العربية":"Vietnam",
    },
    "Indonesia": {
      "English": "Indonesia",
      "Español": "Indonesia",
      "Bahasa": "Indonesia",
      "العربية":"Indonesia",
    },
    "Indonesia": {
      "English": "Indonesia",
      "Español": "Indonesia",
      "Bahasa": "Indonesia",
      "العربية":"Indonesia",
    },
    "Mexico": {
      "English": "Mexico",
      "Español": "México",
      "Bahasa": "Mexico",
      "العربية":"Mexico",
    },
    "Thailand": {
      "English": "Thailand",
      "Español": "Tailandia",
      "Bahasa": "Thailand",
      "العربية":"Thailand",
    },
    "Malaysia": {
      "English": "Malaysia",
      "Español": "Malasia",
      "Bahasa": "Malaysia",
      "العربية":"Malaysia",
    },
    "Philippines": {
      "English": "Philippines",
      "Español": "Filipinas",
      "Bahasa": "Philippines",
      "العربية":"Philippines",
    },
    "Japan": {
      "English": "Japan",
      "Español": "Japón",
      "Bahasa": "Japan",
      "العربية":"Japan",
    },
    "Other areas": {
      "English": "Other areas",
      "Español": "Otras áreas",
      "Bahasa": "Other areas",
      "العربية":"Other areas",
    },
    'Current language does not support pronunciation.': {
      "English": "Current language does not support pronunciation.",
      "Español": "El idioma actual no admite la pronunciación.",
      "Bahasa": "Bahasa saat ini tidak mendukung pengucapan.",
      "العربية":"اللغات الحالية لا تدعمها النطق",
    },
    // 翻译页面语言本地化
    'Any Language': {
      "English": "Any Language",
      "Español": "Detectar idioma",
      "Bahasa": "Deteksi bahasa",
      "العربية":"اختر لغة",
    },
    'Detectar idioma': {
      "English": "Any Language",
      "Español": "Detectar idioma",
      "Bahasa": "Deteksi bahasa",
      "العربية": "اختر لغة",
    },
    'Deteksi bahasa': {
      "English": "Any Language",
      "Español": "Detectar idioma",
      "Bahasa": "Deteksi bahasa",
      "العربية":"اختر لغة",
    },
    'اختر لغة': {
      "English": "Any Language",
      "Español": "Detectar idioma",
      "Bahasa": "Deteksi bahasa",
      "العربية": "اختر لغة",
    },
    "Albanian": {
      "English": "Albanian",
      "Español": "Albanés",
      "Bahasa": "Albanian",
      "العربية":"Albanian",
    },
    "Amharic": {
      "English": "Amharic",
      "Español": "Amárico",
      "Bahasa": "Amharic",
      "العربية":"Amharic",
    },
    "Arabic": {
      "English": "Arabic",
      "Español": "Arábica",
      "Bahasa": "Arabic",
      "العربية":"Arabic",
    },
    "Armenian": {
      "English": "Armenian",
      "Español": "Armenio",
      "Bahasa": "Armenian",
      "العربية":"Armenian",
    },
    "Assamese": {
      "English": "Assamese",
      "Español": "Asamés",
      "Bahasa": "Assamese",
      "العربية":"Assamese",
    },
    "Azerbaijani": {
      "English": "Azerbaijani",
      "Español": "Azerbaiyano",
      "Bahasa": "Azerbaijani",
      "العربية":"Azerbaijani",
    },
    "Basque": {
      "English": "Basque",
      "Español": "Vasco",
      "Bahasa": "Basque",
      "العربية":"Basque",
    },
    "Belarusian": {
      "English": "Belarusian",
      "Español": "Bielorruso",
      "Bahasa": "Belarusian",
      "العربية":"Belarusian",
    },
    "Bengali": {
      "English": "Bengali",
      "Español": "Bengalí",
      "Bahasa": "Bengali",
      "العربية":"Bengali",
    },
    "Bosnian": {
      "English": "Bosnian",
      "Español": "Bosnio",
      "Bahasa": "Bosnian",
      "العربية":"Bosnian",
    },
    "Bulgarian": {
      "English": "Bulgarian",
      "Español": "Búlgaro",
      "Bahasa": "Bulgarian",
      "العربية":"Bulgarian",
    },
    "Burmese": {
      "English": "Burmese",
      "Español": "Birmano",
      "Bahasa": "Burmese",
      "العربية":"Burmese",
    },
    "Catalan": {
      "English": "Catalan",
      "Español": "Catalán",
      "Bahasa": "Catalan",
      "العربية":"Catalan",
    },
    "Cebuano": {
      "English": "Cebuano",
      "Español": "Cebuano",
      "Bahasa": "Cebuano",
      "العربية":"Cebuano",
    },
    "Chichewa": {
      "English": "Chichewa",
      "Español": "Chichewa",
      "Bahasa": "Chichewa",
      "العربية":"Chichewa",
    },
    "Chinese Simplified": {
      "English": "Chinese Simplified",
      "Español": "Chino simplificado",
      "Bahasa": "Chinese Simplified",
      "العربية":"Chinese Simplified",
    },
    "Chinese Traditional": {
      "English": "Chinese Traditional",
      "Español": "Chino tradicional",
      "Bahasa": "Chinese Traditional",
      "العربية":"Chinese Traditional",
    },
    "Corsican": {
      "English": "Corsican",
      "Español": "Corso",
      "Bahasa": "Corsican",
      "العربية":"Corsican",
    },
    "Croatian": {
      "English": "Croatian",
      "Español": "Croata",
      "Bahasa": "Croatian",
      "العربية":"Croatian",
    },
    "Czech": {
      "English": "Czech",
      "Español": "Checo",
      "Bahasa": "Czech",
      "العربية":"Czech",
    },
    "Danish": {
      "English": "Danish",
      "Español": "Danés",
      "Bahasa": "Danish",
      "العربية":"Danish",
    },
    "Dutch": {
      "English": "Dutch",
      "Español": "Holandés",
      "Bahasa": "Dutch",
      "العربية":"Dutch",
    },
    "English": {
      "English": "English",
      "Español": "Inglés",
      "Bahasa": "English",
      "العربية":"English",
    },
    "Esperanto": {
      "English": "Esperanto",
      "Español": "Esperanto",
      "Bahasa": "Esperanto",
      "العربية":"Esperanto",
    },
    "Estonian": {
      "English": "Estonian",
      "Español": "Estonio",
      "Bahasa": "Estonian",
      "العربية":"Estonian",
    },
    "Español": {
      "English": "Spanish",
      "Español": "Español",
      "Bahasa": "Spanish",
      "العربية":"Spanish",
    },
    "Filipino": {
      "English": "Filipino",
      "Español": "Filipino",
      "Bahasa": "Filipino",
      "العربية":"Filipino",
    },
    "Finnish": {
      "English": "Finnish",
      "Español": "Finlandés",
      "Bahasa": "Finnish",
      "العربية":"Finnish",
    },
    "French": {
      "English": "French",
      "Español": "Francés",
      "Bahasa": "French",
      "العربية":"French",
    },
    "Gaelic": {
      "English": "Gaelic",
      "Español": "Gaélico",
      "Bahasa": "Gaelic",
      "العربية":"Gaelic",
    },
    "Galician": {
      "English": "Galician",
      "Español": "Gallego",
      "Bahasa": "Galician",
      "العربية":"Galician",
    },
    "Georgian": {
      "English": "Georgian",
      "Español": "Georgiano",
      "Bahasa": "Georgian",
      "العربية":"Georgian",
    },
    "German": {
      "English": "German",
      "Español": "Alemán",
      "Bahasa": "German",
      "العربية":"German",
    },
    "Greek": {
      "English": "Greek",
      "Español": "Griego",
      "Bahasa": "Greek",
      "العربية":"Greek",
    },
    "Gujarati": {
      "English": "Gujarati",
      "Español": "Gujarati",
      "Bahasa": "Gujarati",
      "العربية":"Gujarati",
    },
    "Haitian": {
      "English": "Haitian",
      "Español": "Haitiano",
      "Bahasa": "Haitian",
      "العربية":"Haitian",
    },
    "Hausa": {
      "English": "Hausa",
      "Español": "Hausa",
      "Bahasa": "Hausa",
      "العربية":"Hausa",
    },
    "Hawaiian": {
      "English": "Hawaiian",
      "Español": "Hawaiano",
      "Bahasa": "Hawaiian",
      "العربية":"Hawaiian",
    },
    "Hebrew": {
      "English": "Hebrew",
      "Español": "Hebreo",
      "Bahasa": "Hebrew",
      "العربية":"Hebrew",
    },
    "Hindi": {
      "English": "Hindi",
      "Español": "Hindi",
      "Bahasa": "Hindi",
      "العربية":"Hindi",
    },
    "Hmong Daw": {
      "English": "Hmong Daw",
      "Español": "Hmong Daw",
      "Bahasa": "Hmong Daw",
      "العربية":"Hmong Daw",
    },
    "Hungarian": {
      "English": "Hungarian",
      "Español": "Húngaro",
      "Bahasa": "Hungarian",
      "العربية":"Hungarian",
    },
    "Icelandic": {
      "English": "Icelandic",
      "Español": "Islandés",
      "Bahasa": "Icelandic",
      "العربية":"Icelandic",
    },
    "Igbo": {
      "English": "Igbo",
      "Español": "Igbo",
      "Bahasa": "Igbo",
      "العربية":"Igbo",
    },
    "Indonesian": {
      "English": "Indonesian",
      "Español": "Indonesio",
      "Bahasa": "Indonesian",
      "العربية":"Indonesian",
    },
    "Irish": {
      "English": "Irish",
      "Español": "Irlandes",
      "Bahasa": "Irish",
      "العربية":"Irish",
    },
    "Italian": {
      "English": "Italian",
      "Español": "Italiano",
      "Bahasa": "Italian",
      "العربية":"Italian",
    },
    "Japanese": {
      "English": "Japanese",
      "Español": "Japonés",
      "Bahasa": "Japanese",
      "العربية":"Japanese",
    },
    "Javanese": {
      "English": "Javanese",
      "Español": "Javanés",
      "Bahasa": "Javanese",
      "العربية":"Javanese",
    },
    "Kannada": {
      "English": "Kannada",
      "Español": "Kannada",
      "Bahasa": "Kannada",
      "العربية":"Kannada",
    },
    "Kazakh": {
      "English": "Kazakh",
      "Español": "Kazajo",
      "Bahasa": "Kazakh",
      "العربية":"Kazakh",
    },
    "Khmer": {
      "English": "Khmer",
      "Español": "Jemer",
      "Bahasa": "Khmer",
      "العربية":"Khmer",
    },
    "Klingon": {
      "English": "Klingon",
      "Español": "Klingon",
      "Bahasa": "Klingon",
      "العربية":"Klingon",
    },
    "Korean": {
      "English": "Korean",
      "Español": "Coreano",
      "Bahasa": "Korean",
      "العربية":"Korean",
    },
    "Kurdish": {
      "English": "Kurdish",
      "Español": "Kurdo",
      "Bahasa": "Kurdish",
      "العربية":"Kurdish",
    },
    "Kyrgyz": {
      "English": "Kyrgyz",
      "Español": "Kirguís",
      "Bahasa": "Kyrgyz",
      "العربية":"Kyrgyz",
    },
    "Lao": {
      "English": "Lao",
      "Español": "Laos",
      "Bahasa": "Lao",
      "العربية":"Lao",
    },
    "Latin": {
      "English": "Latin",
      "Español": "Latín",
      "Bahasa": "Latin",
      "العربية":"Latin",
    },
    "Lao": {
      "English": "Lao",
      "Español": "Laos",
      "Bahasa": "Lao",
      "العربية":"Lao",
    },
    "Latvian": {
      "English": "Latvian",
      "Español": "Letón",
      "Bahasa": "Latvian",
      "العربية":"Latvian",
    },
    "Levantine Arabic": {
      "English": "Levantine Arabic",
      "Español": "Árabe levantino",
      "Bahasa": "Levantine Arabic",
      "العربية":"Levantine Arabic",
    },
    "Lithuanian": {
      "English": "Lithuanian",
      "Español": "Lituano",
      "Bahasa": "Lithuanian",
      "العربية":"Lithuanian",
    },
    "Luxembourgish": {
      "English": "Luxembourgish",
      "Español": "Luxemburgués",
      "Bahasa": "Luxembourgish",
      "العربية":"Luxembourgish",
    },
    "Macedonian": {
      "English": "Macedonian",
      "Español": "Macedónio",
      "Bahasa": "Macedonian",
      "العربية":"Macedonian",
    },
    "Malagasy": {
      "English": "Malagasy",
      "Español": "Madagascarí",
      "Bahasa": "Malagasy",
      "العربية":"Malagasy",
    },
    "Malay": {
      "English": "Malay",
      "Español": "Malayo",
      "Bahasa": "Malay",
      "العربية":"Malay",
    },
    "Malayalam": {
      "English": "Malayalam",
      "Español": "Malayalam",
      "Bahasa": "Malayalam",
      "العربية":"Malayalam",
    },
    "Maltese": {
      "English": "Maltese",
      "Español": "Maltés",
      "Bahasa": "Maltese",
      "العربية":"Maltese",
    },
    "Maori": {
      "English": "Maori",
      "Español": "Maorí",
      "Bahasa": "Maori",
      "العربية":"Maori",
    },
    "Marathi": {
      "English": "Marathi",
      "Español": "Marathi",
      "Bahasa": "Marathi",
      "العربية":"Marathi",
    },
    "Mongolian": {
      "English": "Mongolian",
      "Español": "Mongol",
      "Bahasa": "Mongolian",
      "العربية":"Mongolian",
    },
    "Nepali": {
      "English": "Nepali",
      "Español": "Nepalí",
      "Bahasa": "Nepali",
      "العربية":"Nepali",
    },
    "Norwegian": {
      "English": "Norwegian",
      "Español": "Noruego",
      "Bahasa": "Norwegian",
      "العربية":"Norwegian",
    },
    "Odia": {
      "English": "Odia",
      "Español": "Odia",
      "Bahasa": "Odia",
      "العربية":"Odia",
    },
    "Pashto": {
      "English": "Pashto",
      "Español": "Pashto",
      "Bahasa": "Pashto",
      "العربية":"Pashto",
    },
    "Persian": {
      "English": "Persian",
      "Español": "Persa",
      "Bahasa": "Persian",
      "العربية":"Persian",
    },
    "Polish": {
      "English": "Polish",
      "Español": "Polaco",
      "Bahasa": "Polish",
      "العربية":"Polish",
    },
    "Portuguese": {
      "English": "Portuguese",
      "Español": "Portugués",
      "Bahasa": "Portuguese",
      "العربية":"Portuguese",
    },
    "Punjabi": {
      "English": "Punjabi",
      "Español": "Punjabi",
      "Bahasa": "Punjabi",
      "العربية":"Punjabi",
    },
    "Querétaro Otomi": {
      "English": "Querétaro Otomi",
      "Español": "Querétaro Otomi",
      "Bahasa": "Querétaro Otomi",
      "العربية":"Querétaro Otomi",
    },
    "Romanian": {
      "English": "Romanian",
      "Español": "Rumano",
      "Bahasa": "Romanian",
      "العربية":"Romanian",
    },
    "Russian": {
      "English": "Russian",
      "Español": "Ruso",
      "Bahasa": "Russian",
      "العربية":"Russian",
    },
    "Samoan": {
      "English": "Samoan",
      "Español": "Samoano",
      "Bahasa": "Samoan",
      "العربية":"Samoan",
    },
    "Serbian-Cyrillic": {
      "English": "Serbian-Cyrillic",
      "Español": "Serbio-cirílico",
      "Bahasa": "Serbian-Cyrillic",
      "العربية":"Serbian-Cyrillic",
    },
    "Serbian-Latin": {
      "English": "Serbian-Latin",
      "Español": "Serbio-latino",
      "Bahasa": "Serbian-Latin",
      "العربية":"Serbian-Latin",
    },
    "Shona": {
      "English": "Shona",
      "Español": "Shona",
      "Bahasa": "Shona",
      "العربية":"Shona",
    },
    "Sindhi": {
      "English": "Sindhi",
      "Español": "Sindhi",
      "Bahasa": "Sindhi",
      "العربية":"Sindhi",
    },
    "Sinhala": {
      "English": "Sinhala",
      "Español": "Cingalés",
      "Bahasa": "Sinhala",
      "العربية":"Sinhala",
    },
    "Slovak": {
      "English": "Slovak",
      "Español": "Eslovaco",
      "Bahasa": "Slovak",
      "العربية":"Slovak",
    },
    "Slovenian": {
      "English": "Slovenian",
      "Español": "Esloveno",
      "Bahasa": "Slovenian",
      "العربية":"Slovenian",
    },
    "Somali": {
      "English": "Somali",
      "Español": "Somalí",
      "Bahasa": "Somali",
      "العربية":"Somali",
    },
    "Southern Sotho": {
      "English": "Southern Sotho",
      "Español": "Sotho del sur",
      "Bahasa": "Southern Sotho",
      "العربية":"Southern Sotho",
    },
    "Slovenian": {
      "English": "Slovenian",
      "Español": "Esloveno",
      "Bahasa": "Slovenian",
      "العربية":"Slovenian",
    },
    "Spanish": {
      "English": "Spanish",
      "Español": "Español",
      "Bahasa": "Spanish",
      "العربية":"Spanish",
    },
    "Sundanese": {
      "English": "Sundanese",
      "Español": "Sundanés",
      "Bahasa": "Sundanese",
      "العربية":"Sundanese",
    },
    "Swahili": {
      "English": "Swahili",
      "Español": "Swahili",
      "Bahasa": "Swahili",
      "العربية":"Swahili",
    },
    "Swedish": {
      "English": "Swedish",
      "Español": "Sueco",
      "Bahasa": "Swedish",
      "العربية":"Swedish",
    },
    "Tajik": {
      "English": "Tajik",
      "Español": "Tayiko",
      "Bahasa": "Tajik",
      "العربية":"Tajik",
    },
    "Tamil": {
      "English": "Tamil",
      "Español": "Tamil",
      "Bahasa": "Tamil",
      "العربية":"Tamil",
    },
    "Telugu": {
      "English": "Telugu",
      "Español": "Telugu",
      "Bahasa": "Telugu",
      "العربية":"Telugu",
    },
    "Thai": {
      "English": "Thai",
      "Español": "Tailandés",
      "Bahasa": "Thai",
      "العربية":"Thai",
    },
    "Turkish": {
      "English": "Turkish",
      "Español": "Turco",
      "Bahasa": "Turkish",
      "العربية":"Turkish",
    },
    "Ukrainian": {
      "English": "Ukrainian",
      "Español": "Ucraniano",
      "Bahasa": "Ukrainian",
      "العربية":"Ukrainian",
    },
    "Urdu": {
      "English": "Urdu",
      "Español": "Urdu",
      "Bahasa": "Urdu",
      "العربية":"Urdu",
    },
    "Uzbek": {
      "English": "Uzbek",
      "Español": "Uzbeko",
      "Bahasa": "Uzbek",
      "العربية":"Uzbek",
    },
    "Vietnamese": {
      "English": "Vietnamese",
      "Español": "Vietnamita",
      "Bahasa": "Vietnamese",
      "العربية":"Vietnamese",
    },
    "Welsh": {
      "English": "Welsh",
      "Español": "Galés",
      "Bahasa": "Welsh",
      "العربية":"Welsh",
    },
    "Western Frisian": {
      "English": "Western Frisian",
      "Español": "Frisón occidental",
      "Bahasa": "Western Frisian",
      "العربية":"Western Frisian",
    },
    "Xhosa": {
      "English": "Xhosa",
      "Español": "Xhosa",
      "Bahasa": "Xhosa",
      "العربية":"Xhosa",
    },
    "Yiddish": {
      "English": "Yiddish",
      "Español": "Yídish",
      "Bahasa": "Yiddish",
      "العربية":"Yiddish",
    },
    "Yoruba": {
      "English": "Yoruba",
      "Español": "Yoruba",
      "Bahasa": "Yoruba",
      "العربية":"Yoruba",
    },
    "Yucatec Maya": {
      "English": "Yucatec Maya",
      "Español": "Maya Yucateco",
      "Bahasa": "Yucatec Maya",
      "العربية":"Yucatec Maya",
    },
    "Zulu": {
      "English": "Zulu",
      "Español": "Zulú",
      "Bahasa": "Zulu",
      "العربية":"Zulu",
    },
  }
})
