<!--
* Home Page 组件：dictionary contact
* 用途：展示各种社交媒体的官方账号以及email
-->
<template lang="pug">
.dict_contact
  .contact_box(:key="selectLang")
    .contact_info
      .info_head
        span.head_title {{$TT('Follow and contact us')}}
        img.head_icon(src="@/assets/images/home/contact_info-icon.png")
      .info_context
        span.main_text {{$TT(contactText)}}
    .contact_icons
      .icons_box
        a.email(:href="contactInfo.email")
          img.icon_email(src='@/assets/images/home/contact_icon-email.png')
        //- 暂时无twitter 跳转需求所以隐藏
        //- img.icon_twitter(src='@/assets/images/home/contact_icon-twitter.png')
        a.facebook(:href="contactInfo.facebook")
          img.icon_facebook(src='@/assets/images/home/contact_icon-facebook.png')
        a.ins(:href="contactInfo.instagram")
          img.icon_instagram(src='@/assets/images/home/contact_icon-ins.png')
        a.youtube(:href="contactInfo.youtube")
          img.icon_youtube(src='@/assets/images/home/contact_icon-youtube.png')
</template>

<script>
import { mapState } from 'vuex';
import { data } from '@/config/data';
import connectdata from '@/config/contact';

export default {
  name: 'dictContact',
  data() {
    return {
      iconList: [],
      contactHead: '',
      contactText: 'If you are a content creator and would like to promote our app, contact us here or send an email to udictionary01@gmail.com',
      contactInfo: '',
    }
  },
  mounted() {
    this.iconList = data.contactIcons;
    this.contactInfo = connectdata.contactInfo[this.$store.state.selectLang];
  },
  computed: {
    ...mapState(['selectLang']),
  },
  watch: {
    '$store.state.selectLang': {
      handler(val) {
        this.contactInfo = connectdata.contactInfo[val];
      },
    },
  },
}
</script>

<style lang="stylus" scoped>
.dict_contact
  width 100%
  min-width 1300px
  height 400px
  display flex
  justify-content center
  align-items center
  .contact_box
    width 1300px
    height 224px
    background: #FDF4EE;
    border-radius 20px
    display flex
    .contact_info
      width 50%
      .info_head
        margin-left 56px
        margin-top 67px
        .head_title
          font-weight bold
          font-size 32px
          line-height 39px
          color #FF592B
        .head_icon
          margin-left 19px
      .info_context
        width 436px
        margin-top 7px
        margin-left 56px
        font-size 18px
        line-height 24px
    .contact_icons
      margin-top 62px
      margin-left 92px
      width 500px
      height 100px
      border 2px solid #FFFFFF
      border-radius 50px
      background rgba(255, 255, 255, 0.3)
      backdrop-filter blur(27px)
      .icon
        height 30px
        margin-top 40px
        margin-left 60px
      .icon_email
        width 25px
        margin-left 87px
        margin-top 40px
      .icon_facebook
        width 17px
        margin-left 67px
        transform translateY(5px)
      .icon_instagram
        width 30px
        margin-left 64px
        transform translateY(4px)
      .icon_youtube
        width 33px
        margin-top 38px
        margin-left 57px
.ar
  .head_title,.info_context
    direction rtl !important
    text-align right !important
</style>
