<!--
* 通用组件：media-box 组件
* 用途：主要解决轮播图中为了在图片，视频中显示作者，流量数以及简单的介绍的功能，解决用 img 所产生的大量重复劳动。
* 其中 playBtn & author & viewer这些属性是可选的，本次中让 UI 直接在图片里添加了。
-->
<template lang="pug">
.media_box
  div(
      :class="[type==='image'?'image': type==='portraitVideo'?'vertical_box': 'horizontal_box']"
    )
    img.main_poster(:src="link" alt="here is an image")
    img.playBtn(v-show="playBtn" :src="playBtn" alt="is a play button picture")
    .author_part(v-show="author_icon")
      img.author_icon(:src="author_icon" alt="author's protriat photo")
      span.author_name(v-show="author") {{author}}
    .content_part(v-show="describe")
      .viewers(v-show="viewers")
        span.view_count {{viewers}}
        |   Views
      .describe
        span.content {{describe}}
</template>
<script>

export default {
  name: 'mediaBox',
  props: ['link', 'playBtn', 'author', 'author_icon', 'describe', 'viewers', 'type'],
  methods: {
  },
}
</script>
<style lang="stylus" scoped>
.media_box
  position relative
  .image
    .main_poster
      height 560px
  .vertical_box
    width 420px
    height 560px
    .main_poster
      transform translateX(50px)
      height 560px
    .playBtn
      position absolute
      top 250px
      left 150px
  .horizontal_box
    width 475px
    margin 0 20px
    .main_poster
      height 264px
    .playBtn
      position absolute
      top 106px
      left 210px
    .content_part
      position absolute
      top 170px
      left 32px
      width 356px
      color #FFFFFF
      font-size 12px
      line-height 15px
      .view_count
        font-weight 900
      .describe
        font-size 18px
        line-height 25px
        font-weight 600
        margin-top 8px
  .author_part
    position absolute
    display flex
    align-items center
    background rgba(255, 255, 255, 0.4)
    border 1px solid rgba(255, 255, 255, 0.8)
    border-radius 19px
    top 32px
    left 32px
    width 113px
    height 32px
    .author_icon
      width 32px
      border-radius 50%
    .author_name
      font-size 12px
      font-weight 500
      line-height 17px
      text-align center
      color #FFFFFF
      text-shadow 0px 1px 1px rgba(0, 0, 0, 0.2)
      margin-left 7px
  .content_part
      position absolute
      top 450px
      left 32px
      width 356px
      color #FFFFFF
      font-size 12px
      line-height 15px
      .view_count
        font-weight 900
      .describe
        font-size 18px
        line-height 25px
        font-weight 600
        margin-top 8px
</style>
