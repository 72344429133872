import 'babel-polyfill'
import Vue from 'vue'
import CommonVideo from '@webfront-components-vue/vue-common-video'
import inter from '@/config/inter'
import infiniteScroll from 'vue-infinite-scroll'
import store from '@/store/index'
import '@/libs/rem'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false
Vue.use(CommonVideo)
Vue.use(infiniteScroll)
Vue.prototype.log = function log(event) {
  window.ga('web.send', {
    hitType: 'event',
    eventCategory: 'user_behavior',
    eventAction: event,
  })
}

new Vue({
  router,
  inter,
  store,
  render: (h) => h(App),
}).$mount('#app')
