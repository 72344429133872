<template lang="pug">
.swiper-box
  .swiper-container(
    :navigation="true"
    :pagination="{ 'type': 'fraction'}",
  )
    .swiper-wrapper(@click="stopSwiper")
      .swiper-slide(v-for="(item, index) in bannerData" ,:key="item.url")
        .swiper-link(v-if="item.type !== 'video'")
          img.image(:src="item.url")
          .apk-download-btn(v-if="item.showDownload") {{$TT('APK DOWNLOAD')}}
        .video-container(v-if="item.type === 'video'")
          img.video-poster(:src="item.url")
          img.play-button(
            src="~@/assets/images/home/play_btn.png")
    .swiper-video-container(
      v-show="playingStatus !== 0",
      @click.stop="stopSwiper")
      common-video.swiper-video(
        v-if="videoSrc",
        @mousedown.native.stop,
        @mousemove.native.stop,
        @mouseup.native.stop,
        ref="video",
        :needControl="false",
        :videoSrc="videoSrc",
        @ended="onVideoEnded")
    .swiper-pagination
    .swiper-button-prev
    .swiper-button-next

</template>
<script>
import data from '@/config/banner'
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';
import playButton from '@/assets/images/home/play_btn.png'
import service from '@/services/ip'
import links from '@/config/links'

export default {
  name: 'swiperMain',
  components: {
  },
  props: ['lang'],
  data() {
    return {
      mySwiper: '',
      bannerData: [],
      playingStatus: 0, // 0 为poster样式，1为播放中
      timeId: null,
      playButton,
      videoSrc: '',
      oneLink: links.oneLink,
      apkLink: links.apkLink,
    }
  },
  watch: {
    '$store.state.selectLang': {
      handler(val, pre) {
        console.log(val, pre)
        this.bannerData = data.banners[val];
        this.playingStatus = 0
      },
    },
    bannerData: {
      handler() {
        setTimeout(() => {
          this.buildSwiper();
        }, 0)
      },
      deep: true,
    },
  },
  created() {
    const { lang } = this.$route.params;
    this.bannerData = data.banners[lang];
    service.getIp()
      .then((res) => {
        const country = res.data ? res.data.country : ''
        this.oneLink = country === 'IN' ? links.inOneLink : links.oneLink
      })
  },
  mounted() {
    this.buildSwiper();
    console.log('selectLang', this.selectLang)
  },
  methods: {
    buildSwiper() {
      if (this.mySwiper) {
        this.mySwiper.destroy()
      }
      const videoBanner = this.bannerData && this.bannerData.find((item) => item.type === 'video')
      if (videoBanner) {
        this.videoSrc = videoBanner.link
        if (this.$refs.video) {
          this.$refs.video.mPlayer.src(videoBanner.link)
          this.playingStatus = 0
        }
      }
      this.mySwiper = new Swiper('.swiper-container', {
        loop: true, // 循环
        speed: 2000, // 滚动速度
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, // 修改swiper的父元素时，自动初始化swiper
        autoplay: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
        },
        on: {
          activeIndexChange: (swiper) => {
            clearTimeout(this.timeId)
            const { color } = this.bannerData[swiper.realIndex];
            this.$emit('changeBg', color);
            if (this.mySwiper && this.mySwiper.autoplay && !this.mySwiper.autoplay.running) {
              this.$refs.video.pause()
              this.playingStatus = 0
              this.timeId = setTimeout(() => {
                this.mySwiper.slideNext()
                this.mySwiper.autoplay.start()
              }, 3000)
            }
          },
        },
      });
    },
    stopSwiper(e) {
      const isApk = e.target.classList.contains('apk-download-btn')
      if (this.bannerData[this.mySwiper.realIndex].type !== 'video') {
        const logType = isApk ? 'ud_web_download_button_click' : 'ud_web_onelink_button_click'
        this.log(logType)
        window.location.href = isApk ? this.apkLink : this.oneLink
        return
      }
      if (this.playingStatus === 1) {
        this.$refs.video.pause()
        this.playingStatus = 0
        this.mySwiper.autoplay.start()
        return
      }
      clearTimeout(this.timeId)
      this.playingStatus = 1
      this.mySwiper.autoplay.stop()
      this.$refs.video.play()
    },
    onVideoEnded() {
      this.$refs.video.play()
    },
  },
}
</script>
<style lang="stylus" scoped>
.swiper-box
  position absolute
  width 100%
  top 1220x
  .swiper-container
    position relative
    width 1200px
    height 840px
  .swiper-slide
    height 840px
    text-align: center;
    font-size: 18px;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    .image
      width 1200px
      pointer-events none
      // height 840px
    .video-poster
      width 1200px
      height 675px
      border-radius 40px
      overflow hidden
.swiper-video-container
  position absolute
  top 80px
  left 0
  width 1200px
  height 675px
  border-radius 40px
  overflow hidden
  background transparent
  z-index 1
  cursor pointer
  >>> video
    object-fit fill
    width 1200px
    height 675px
  >>> video:focus
    outline none
.video-container
  position relative
.play-button
  width 128px
  height 128px
  cursor pointer
  position absolute
  top 50%
  left 50%
  transform translate(-50%, -50%)
.swiper-link
  position relative
.apk-download-btn
  position absolute
  top 495px
  left 50%
  transform translateX(-592px)
  background #FF592B
  border-radius 10.5px
  min-width 400px
  padding 18px 0
  text-align center
  color #fff
  font-size 24px
  line-height 29px
  font-weight 600
  cursor pointer
  z-index 100
  &::after
    content ''
    display inline-block
    width 25px
    height 28px
    vertical-align -15%
    margin-left 14px
    background url('~@/assets/images/home/download_icon.png') 0 0 / 100% 100% no-repeat
.ar
  .apk-download-btn
    direction rtl
    &::after
      margin-left 0
      margin-right 14px
</style>
