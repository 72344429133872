<!--
* Home Page 组件： dictionary header
* 用途：头部组件，用于展示特点和下载方式。
-->
<template lang="pug">
.dict_header(ref="bgColor")
  .header_box
    swiper-main.swiper(@changeBg="swapColor" )
</template>

<script>
import swiperMain from './swiperMain.vue'

export default {
  name: 'dictHeader',
  components: {
    swiperMain,
  },
  methods: {
    swapColor(val) {
      this.$refs.bgColor.style.background = val;
    },
  },
}
</script>

<style lang="stylus" scoped>
.dict_header
  width 100%
  min-width 960px
  height 720px
  .header_box
    .swiper
      position absolute
      margin 0 auto
      left 50%
      transform translateX(-600px)
      width 1200px
      height 840px

</style>
