/* eslint-disable */
export function getParameterByName(name, url = window.location.href) {
  const key = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${key}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

/* 用于固定当前页面，无法滑动 */
export function fixedBody() {
  document.body.parentNode.style.overflow= 'hidden';
}

/* 恢复页面滑动 */
export function looseBody() {
  document.body.parentNode.style.overflow = 'auto';
}

/* 用于模糊查找 */
export function fuzzySearch(data, hint) {
  let answer = data.filter(list => {
    const reg = new RegExp(`^${hint}`, 'i')
    return reg.test(list[0])
  });
  return answer;
}

/* 使用 dom 指令创建节点 */
export function createDomNode(data, detail) {
  const element = document.querySelector(`.${data.id}`);
  const children = detail[data.id].map((item) => {
    const [tag, content] = item;
    if (tag[0] === 'h' || tag === 'p' || tag === 'span' || tag === 'strong' || tag === 'em') {
      const hElement = document.createElement(tag);
      const text = document.createTextNode(content);
      hElement.appendChild(text);
      hElement.style['letter-spacing'] = '0.7px';
      return hElement;
    }
    if (tag === 'a') {
      const link = document.createElement(tag);
      const url = document.createAttribute('href');
      url.value = content;
      link.setAttributeNode(url);
      let textContent = content;
      if (content.substring(0,7) === 'mailto:') {
        textContent = content.substring(7);
      }
      const text = document.createTextNode(textContent)
      link.appendChild(text);
      link.style.color = 'blue';
      return link;
    }
    if (tag === 'img') {
      const img = document.createElement(tag);
      const src = document.createAttribute('src');
      src.value = content;
      img.setAttributeNode(src);
      img.style['width'] = '100%'
      img.style['margin'] = '20px 0'
      return img;
    }
    if (tag === 'br') {
      const br = document.createElement(tag);
      return br;
    }
    if (tag === 'ul' || tag === 'ol') {
      const element = createList(tag, content);
      return element;
    }
    return '';
  });
  children.forEach((el) => {
    element.appendChild(el);
  });
  return element;
}

/* 创建 ul/ol 的列表元素, 考虑到多级列表 */
function createList(tag, content) {
  const list = document.createElement(tag);
  content.forEach((item) => {
    if(item[0] === 'li') {
      const li = document.createElement('li');
      const text = document.createTextNode(item[1]);
      li.appendChild(text);
      list.appendChild(li);
    } else {
      list.appendChild(createList(item[0], item[1]));
    }
  });
  return list;
}

/* 处理国际化语言的问题，不同语言有的从左到右，有的从右到左 */
export function getLangClass(val) {
  let language = '';
  switch (val) {
    case 'English': language = 'en';
      break;
    case 'Español': language = 'span';
      break;
    case 'Bahasa': language = 'ba';
      break;
    case 'العربية': language = 'ar';
      break;
    default: language = 'en';
  }
  return language;
}

/* 处理移动端国际化语言的问题，不同语言有的从左到右，有的从右到左 */
export function getMobileLangClass(val) {
  let language = '';
  switch (val) {
    case 'en': language = 'English';
      break;
    case 'es': language = 'Español';
      break;
    case 'id': language = 'Bahasa';
      break;
    case 'ar': language = 'العربية';
      break;
    default: language = 'English';
  }
  return language;
}

/* 处理时间数据，数据格式: yyyy-mm-dd */
export function dateFormatter(date) {
  const year = date.getFullYear()
  const month = date.getMonth() + 1 > 9
    ? date.getMonth() + 1
    : `0${date.getMonth() + 1}`
  const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
  return `${year}-${month}-${day}`
}

/*  发音处理 */
export function getPronounceUrl(url,lan) {
  return `https://inter.youdao.com/dictvoice?audio=${url}&type=1&le=${lan}&keyfrom=`
}
