<!--
* 通用组件：下载图标，主要为google play 和 apple store，后期可拓展。
* 主要用处：公用的下载选项，包含所有选择，根据需求来进行选择展示。
-->
<template lang="pug">
.download_component
  a(:href="img_href", @click="log('ud_web_onelink_button_click')")
    img.google_icon(
      src="@/assets/images/home/google_download-icon.png"
      alt="google play download link"
      :class="'google_' + size"
    )
  a(:href="img_href", @click="log('ud_web_onelink_button_click')")
    img.ios_icon(
      src="@/assets/images/home/ios_download-icon.png"
      alt="apple store download.link"
      :class="'ios_'+size"
    )
</template>

<script>
import service from '@/services/ip'
import links from '@/config/links'

export default {
  name: 'downloadIcon',
  props: ['size'],
  data() {
    return {
      img_href: links.oneLink,
      smallIcon: true,
    }
  },
  created() {
    service.getIp()
      .then((res) => {
        const country = res.data ? res.data.country : ''
        if (country === 'IN') {
          this.img_href = links.inOneLink
        }
      })
  },
  mounted() {
    this.smallIcon = this.isSmall
  },
}
</script>

<style lang="stylus" scoped>
.download_component
  z-index 10
  display flex
  .ios_icon
    margin-left 18px
  .google_small
    height 40px
  .ios_small
    height 40px
  .google_mini
    width 105px
  .ios_mini
    width 93px
  .google_mobile
    width 0.65rem
  .ios_mobile
    width 0.6rem
</style>
