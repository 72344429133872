import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    videoInfo: {},
    animation: {},
    selectLang: '',
  },
  mutations: {
    setVideoInfo(state, mode) {
      state.videoInfo = mode || {};
    },
    setAnimation(state, mode) {
      state.animation = mode || {};
    },
    setLanguage(state, mode) {
      state.selectLang = mode || 'English';
    },
  },
  actions: {
    setVideoInfo({ commit }, mode) {
      commit('setVideoInfo', mode);
    },
    setAnimation({ commit }, mode) {
      commit('setAnimation', mode);
    },
    setLanguage({ commit }, mode) {
      commit('setLanguage', mode);
    },
  },
  modules: {
  },
});

export default store;
