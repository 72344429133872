import { render, staticRenderFns } from "./featurePanel.vue?vue&type=template&id=2c686f84&scoped=true&lang=pug&"
import script from "./featurePanel.vue?vue&type=script&lang=js&"
export * from "./featurePanel.vue?vue&type=script&lang=js&"
import style0 from "./featurePanel.vue?vue&type=style&index=0&id=2c686f84&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c686f84",
  null
  
)

export default component.exports